/**
 * Design System for Piaggio Group
*/

////////////////////////////////////////
// General
$general-link-color: $color-secondary;
$general-outline-width: 3px;
$general-outline-color-on-light: $color-tertiary-2;
$general-outline-color-on-dark: $white;
$general-outline-color-on-medium: $color-tertiary-2;
$general-heading-l-decoration: false;
$general-body-padding: 1.5rem;
$border-radius: rem(8);
$card-article-border-bottom-right-radius: rem(10);
$card-article-border-top-left-radius: rem(10);
$card-article-border-top-right-radius: 0;
$card-article-border-bottom-left-radius: 0;
////////////////////////////////////////
// Button

$button-text-underline: false;

$button-primary-color: $color-primary;
$button-primary-text-color: $text-color-white;

$button-secondary-color: $color-tertiary-1;
$button-secondary-text-color: $text-color-white;
$secondary-button-color: $white;
$secondary-opacity: 1;

$button-outline-text-color: $button-primary-color;
$button-outline-border-color: $button-primary-color;

$button-m-height: rem(50);
$button-l-height: rem(80);

$button-go-to-top-radius: $button-m-height 0 0 $button-m-height;

$button-m-radius: 30px;
$button-l-radius: 30px;

$button-m-text-transform: unset;
$button-l-text-transform: unset;

$space-size-s-button-v: 10px;
$space-size-s-button-h: 25px;
$space-size-m-button-v: 20px;
$space-size-m-button-h: 30px;
$space-size-l-button-v: 20px;
$space-size-l-button-h: 35px;

$button-s-padding-v: $space-size-s-button-v;
$button-s-padding-h: $space-size-s-button-h;

$button-m-padding-v: $space-size-m-button-v;
$button-m-padding-h: $space-size-m-button-h;

$button-cta-padding-v: 0;
$button-cta-padding-h: 0;

$button-l-padding-v: $space-size-l-button-v;
$button-l-padding-h: $space-size-l-button-h;

$button-m-margin: 0.9em;
$button-l-margin: 1em;

$button-s-font-size: 0.9rem;
$button-m-font-size: 1.5rem;
$button-l-font-size: 2rem;

// Button text color
$font-button-text-color: $color-secondary;
$font-button-text-color-section-secondary: $text-color-black;

////////////////////////////////////////
// Body
$body-effect: false;

////////////////////////////////////////
// Header
$header-transparent-not-sticky-pdp: false;
$header-effect: false;
$header-height-small: 7rem;
$header-height-large: 7rem;
$header-background: $color-background;
$header-color-on-background: $color-on-background-primary;
$header-border: false;
$header-border-radius-small: 0;
$header-border-radius-large: 0;
$header-margin-small: 0;
$header-margin-large: 0;
$header-shortcuts-align: center;
$header-shortcuts-text-decoration: none;
$header-shortcuts-text-transform: normal;
$header-shortcuts-text-decoration: none;
$header-shortcuts-text-transform: normal;
$header-services-color: $header-color-on-background;
$header-services-size: 2.4rem;
$header-hamburger-color: $header-services-color;
$header-hamburger-size: 2.4rem;

////////////////////////////////////////
// Navigation

$navigation-outline-color: $general-outline-color-on-light;

$navigation-level1-background-small: $color-background;
$navigation-level2-background-small: $color-background;
$navigation-background-large: $color-background;
$navigation-background-gradient-large: false;

$navigation-background-decoration: false;
$navigation-background-decoration-color: $color-gray-lightest;
$navigation-background-decoration-font-size: 16px;
$navigation-background-decoration-left-position: auto;

$navigation-layout-align-items: center;

$navigation-space-h: 30px;
$navigation-space-v: 20px;

// Navigation Typography
$navigation-item-color: $color-on-background;
$navigation-item-font-weight-small: bold;
$navigation-item-font-transform-small: unset;

$navigation-item-font-weight-large: bold;
$navigation-item-font-transform-large: unset;
$navigation-item-font-style-large: normal;

$navigation-item-active-color: $color-primary;
$navigation-item-active-text-decoration: none;
$navigation-item-active-font-weight: $navigation-item-font-weight-small;

$navigation-nested-title-color: $navigation-item-color;

$navigation-item-nested-color: inherit;
$navigation-item-nested-font-weight-small: normal;
$navigation-item-nested-font-transform-small: unset;

$navigation-item-nested-font-weight-large: normal;
$navigation-item-nested-font-transform-large: unset;
$navigation-item-nested-font-style-large: normal;

$navigation-item-nested-margin: auto;

// Navigation Layout alignment
$navigation-content-height-large: 480px;
$navigation-menu-alignment: center;
$navigation-menu-nested-justify: center;
$navigation-menu-nested-align: unset;
$navigation-highlight-height-large: $navigation-content-height-large;

// Navigation Others
$navigation-item-arrow-color: $color-primary;
$navigation-item-align: left;
$navigation-item-divider: false;
$navigation-item-arrow: true;
$navigation-item-font-style: normal;

$navigation-item-back-color: $text-color-black;
$navigation-item-back-arrow-color: $text-color-black;
$navigation-item-back-background: transparent;

$navigation-item-active-close: true;
$navigation-editorial-divider-parent: list;
$navigation-editorial-divider: true;
$navigation-nested-brand-icon: false;
$navigation-nested-title: false;

//$navigation-nested-width: 50%;
$navigation-nested-align-large: unset;
$navigation-nested-divider-large: false;

////////////////////////////////////////
// Header-V2
$header-v2-effect: false;
$header-v2-border: false;
$header-v2-border-radius-small: 0;
$header-v2-border-radius-large: 0;
$header-v2-margin-small: 0;
$header-v2-margin-large: 0;
$header-v2-shortcuts-align: center;
$header-v2-shortcuts-text-decoration: none;
$header-v2-shortcuts-text-transform: uppercase;
$header-v2-color-on-background: $color-on-secondary;
$header-v2-color-on-background: $color-on-secondary;
$header-v2-services-color: $header-color-on-background;
$header-v2-services-size: 2rem;
$header-v2-background: $color-secondary;
$header-v2-nav-background-color: $white;
$header-v2-menu-services-icon-color: $color-secondary;
$header-v2-services-nav-background-color: $white;
$header-v2-services-nav-background-color-mb: $white;
$header-v2-services-nav-background-color-black: $color-secondary;
$header-v2-contact-us-voice-font-size: rem(20);
$header-v2-modal-background-color: $color-secondary;

////////////////////////////////////////
// Navigation-V2
$navigation-v2-background-color: $color-surface;
$navigation-v2-item-color: $color-on-background;
$navigation-v2-menu-font-size: $font-button-m-font-size;
$navigation-v2-menu-text-color: $color-secondary;
$navigation-v2-menu-text-color-active: #575A5A;
$navigation-v2-menu-font-weight: $font-body-ms-font-weight;
$navigation-v2-menu-font-weight-l: $font-quote-font-weight;
$navigation-v2-header-actions-mb-background-color: #F1F1F1;
$navigation-v2-menu-voice-icon-color: $color-secondary;
$navigation-v2-header-menu-services-icon-font-size: $font-heading-m-font-size-small;
$navigation-v2-header-menu-services-text-font-size: $font-button-m-font-size;
$navigation-v2-header-menu-services-text-font-weight: $font-body-ms-font-weight;
$navigation-v2-header-menu-services-text-font-style: $font-body-s-font-style;
$navigation-v2-header-menu-services-text-line-height: $font-body-s-font-size;

////////////////////////////////////////
// Navigation-V2 > Main Navigation item V2
$main-navigation-item-v2-overlay-visible-bg-color: rgba(0,0,0, 0.6);
$main-navigation-item-v2-menu-nested-background-color: $color-surface;
$main-navigation-item-v2-menu-nested-back-button-text-color: $color-secondary;
$main-navigation-item-v2-menu-nested-back-button-font-size: $font-button-m-font-size;
$main-navigation-item-v2-menu-nested-back-h2-text-color: $color-secondary;
$main-navigation-item-v2-menu-nested-back-h2-font-size: $font-heading-s-font-size;
$main-navigation-item-v2-menu-nested-back-h2-font-weight: $font-heading-s-font-weight;
$main-navigation-item-v2-menu-nested-back-icon-color: $color-secondary;
$main-navigation-item-v2-menu-nested-back-icon-font-size: $font-body-s-font-size;
$main-navigation-item-v2-menu-nested-back-link-font-weight: $font-body-ms-font-weight;
$main-navigation-item-v2-menu-nested-back-link-font-size: $font-button-m-font-size;
$main-navigation-item-v2-menu-nested-back-link-font-color: $color-secondary;
$main-navigation-item-v2-menu-nested-back-link-font-style: $font-body-s-font-style;
$main-navigation-item-v2-menu-nested-back-link-line-height: 100%;

////////////////////////////////////////
// Navigation-V2 > Product List V2
$product-list-v2-card-product-font-size: $font-body-xs-font-size;
$product-list-v2-card-product-font-style: $font-body-xs-font-style;
$product-list-v2-card-product-font-weight: $font-body-xs-font-weight;
$product-list-v2-card-product-line-height: $font-body-xs-line-height;
$product-list-v2-card-product-text-transform: uppercase;
$product-list-v2-card-product-font-color: $color-icon-header-community-calendar;

////////////////////////////////////////
// Navigation-V2 > Card Highlights V2
$card-highlights-v2-text-font-size: $font-body-font-size;
$card-highlights-v2-text-font-color: $color-surface;
$card-highlights-v2-text-font-weight: $font-heading-s-font-weight;
$card-highlights-v2-text-font-style: $font-heading-s-font-style;
$card-highlights-v2-text-line-height: $font-heading-s-line-height;
$card-highlights-v2-font-family: $font-body;
$card-highlights-v2-cta-font-size: $font-body-xs-font-size;
$card-highlights-v2-cta-font-color: $color-surface;
$card-highlights-v2-cta-font-weight: $font-heading-s-font-weight;
$card-highlights-v2-cta-font-style: $font-heading-s-font-style;
$card-highlights-v2-cta-line-height: $font-heading-s-line-height;
$card-highlights-v2-cta-icon-font-size: $font-button-m-font-size;

////////////////////////////////////////
// Navigation-V2 > Card Highlights V2 Outlined
$card-highlights-v2-cta-outlined-font-family: $font-heading;
$card-highlights-v2-cta-outlined-font-color: $color-surface;
$card-highlights-v2-cta-outlined-font-size: $font-body-font-size;
$card-highlights-v2-cta-outlined-font-style: $font-heading-s-font-style;
$card-highlights-v2-cta-outlined-font-weight: $font-heading-xs-font-weight;
$card-highlights-v2-cta-outlined-line-height: $font-heading-xs-line-height;

////////////////////////////////////////
// Footer
$footer-outline-color: $general-outline-color-on-light;
$footer-background: $color-neutro-xlight;
$footer-legal-background: $color-neutro-xlight;
$footer-primary-text-color: $text-color-black;

////////////////////////////////////////
// Card Highlight
$card-highlight-effect: false;
$card-highlight-content-position: false;
$card-highlight-content-bottom: rem(10);
$card-highlight-content-right: 0;
$card-highlight-content-left: 0;
$card-highlight-content-background: transparent;
$card-highlight-content-text-align: left;
$card-highlight-content-overlap: 0;
$card-highlight-content-plus: false;
$card-highlight-content-arrow: true;
$card-highlight-border-radius: $border-radius;
$card-highlight-text-color: $text-color-white;
$card-highlight-cta-color: $color-on-primary;
$card-highlight-cta-color-background: transparent;

////////////////////////////////////////
// Swatches
$swatches-align: center;
$swatches-color-icon-size: rem(13);
$swatches-color-icon-size-l: rem(20);
$swatches-color-icon-size-xl: rem(30);
$swatches-color-icon-line-height-xl: rem(20);
$swatches-color-icon-size-xl-active: rem(42);
$swatches-active-color: $black;
$swatches-inactive-color: #E9E9E9;
$swatches-color-darken: true;
$swatches-box-shadow: false;

////////////////////////////////////////
// Card Product
$card-product-align: center;
$card-product-border-radius: $border-radius;
$card-product-hover-icon: false;
$card-product-hover-roller: false;
$card-product-hover-img: scale(1.1);
$card-product-bg: $color-background;
$card-product-name-color: $color-on-background-tertiary-1;
$card-product-accessory-bg: $color-background;
$card-product-accessory-img-border-color: transparent;
$card-product-accessory-border-color: transparent;
$card-product-accessory-name-color: $color-on-surface-tertiary-1;
$card-product-accessory-price-color: $color-on-surface;
$card-product-accessory-name-height: true;
$card-product-family-name-overlap: false;
$card-product-promo-border-radius: 4px;
$card-product-promo-background: $color-tertiary-2;
$card-product-promo-color: $color-on-tertiary-2;
$card-product-price-divider-left: rem(-9);
$card-product-price-divider-size: 2rem;
$card-product-form-control-position-top: rem(10);
$card-product-form-control-position-right: rem(10);

////////////////////////////////////////
// Gallery carousel
$gallery-border-radius: calc(#{$border-radius} * 2);
$gallery-border-radius-v2: 0;
$gallery-shape-nav-display: none;
$gallery-nav-button-height: 100%;
$gallery-nav-button-width: rem(110);
$gallery-nav-button-bg: $white;
$gallery-nav-button-opacity: 1;
$gallery-nav-button-top: 50%;
$gallery-nav-button-prev-left: 0;
$gallery-nav-button-prev-right: auto;
$gallery-nav-button-icon-padding-left: rem(20);
$gallery-nav-button-icon-padding-left-hover: rem(30);
$gallery-nav-button-icon-padding-right: rem(20);
$gallery-nav-button-icon-padding-right-hover: rem(30);
$gallery-nav-button-color: $color-primary;
$gallery-nav-button-icon-radius: 50%;
$gallery-nav-button-icon-height: rem(110);
$gallery-nav-button-prev-transform: translate(-50px, -50%);
$gallery-nav-button-prev-hover-transform: auto;
$gallery-nav-button-next-transform: translate(50px, -50%);
$gallery-nav-button-next-hover-transform: auto;
$gallery-dot-current-bg: $color-primary;
$gallery-dot-current-border-color: $color-primary;

$gallery-slide-img-video-bg: $color-primary;

////////////////////////////////////////
// Hotspot Carousel

$hotspot-nav-button-color: $color-primary;
$vehicle-premium-nav-button-color: $color-primary;
$hotspot-nav-button-top: 50%;
$vehicle-premium-nav-button-top: 50%;

////////////////////////////////////////
// Hero heading
$hero-heading-title-bottom: rem(48);
$hero-heading-title-color: $text-color-white;
$hero-heading-content-bg: rgba($black, 0.2);
$hero-heading-font-size-big: rem(88);
$hero-heading-font-size-small: rem(32);
$hero-heading-pl: rem(40);
$hero-heading-pl-mobile: rem(20);
$hero-heading-pr: rem(40);
$hero-heading-pr-mobile: rem(20);

////////////////////////////////////////
// Floating shortcuts

$border-radius-top: 0;
$border-radius-bottom: 0;

////////////////////////////////////////
// Editorial
$editorial-text-heading-l-color: $color-secondary;
$editorial-figure-img-border-radius: calc(#{$border-radius} * 2);
$editorial-align-right-padding-right: 0;
$editorial-align-left-padding-left: 0;
$editorial-list-type: disc;

$editorial-border-radius: 0;
$editorial-background-decoration: false;
$editorial-background-decoration-color: $white;
$editorial-background-decoration-font-size: 16px;
$editorial-background-decoration-right-position: auto;
$editorial-background-decoration-left-position: auto;

$editorial-background-decoration-hide-mobile: true;

////////////////////////////////////////
// Editorial blockquote
$editorial-blockquote-quote-font-size: rem(90);
$editorial-blockquote-quote-color: $color-primary;

////////////////////////////////////////
// Editorial icon
$editorial-icon-secondary-color: inherit;
$editorial-icon-title-after-border-bottom: 0;
$editorial-icon-title-after-margin-top: 0;

////////////////////////////////////////
// Editorial tabs
$editorial-tabs-nav-link-color: $color-on-background;
$editorial-tabs-nav-link-border-color: $color-gray-lightest;
$editorial-tabs-nav-link-active-color: $color-surface;
$editorial-tabs-nav-link-active-border-color: $color-active-green;
$editorial-tabs-nav-link-active-border-color-black: $color-on-background;
$editorial-tabs-na-link-active-title-font-weight-bold: $font-heading-ms-font-weight;

////////////////////////////////////////
// Editorial cover image
$editorial-cover-content-bg: rgba($color-secondary, 0.9);
$editorial-cover-content-bg-width-mobile: 92%;
$editorial-cover-content-bg-left-mobile: 4%;
$editorial-cover-content-bg-height: 90%;
$editorial-cover-content-bg-top: 4%;
$editorial-cover-content-mix-blend-mode: unset;
$editorial-cover-content-color: $color-on-secondary;
$editorial-cover-content-border-radius: $border-radius;
$editorial-cover-content-border-top-left-radius: $border-radius;
$editorial-cover-content-padding: rem(12) rem(34);
$editorial-cover-content-padding-medium: rem(34) rem(54);
$editorial-cover-content-padding-mobile: rem(34) rem(34);
$editorial-cover-content-padding-zoom-400: rem(24);
$editorial-cover-container-height: calc(100% - 4.8rem);
$editorial-cover-container-margin-top: rem(24);
$editorial-cover-content-title-after: false;
$editorial-cover-button-text-color: $color-on-secondary;

////////////////////////////////////////
//Breadcrumb
$breadcrumb-arrow: inline-block;

////////////////////////////////////////
// Product presentation
$product-presentation-title-transform: none;
$product-presentation-color: $color-primary;
$product-presentation-title-spacing: rem(15) 0 0 0;
$product-presentation-code-spacing: 0 0 rem(20);
$product-presentation-abstract-spacing: rem(40) 0 rem(20);
$product-presentation-price-divider-size: rem(20);
$product-presentation-price-divider-right: rem(-17);
$product-presentation-background-decoration: false;
$product-presentation-border-bottom: $color-gray-light;
$product-presentation-tooltip-top: rem(-10);
$product-presentation-tooltip-right: rem(-30);
$product-presentation-tooltip-top-m: rem(-10);
$product-presentation-tooltip-right-m: rem(-50);
$product-presentation-tooltip-content-top: rem(100);
$product-presentation-tooltip-content-bottom: rem(64);
$product-presentation-price-margin: $space-size-l 0 2rem;
$product-presentation-price-margin-m: $space-size-l auto 2rem;

////////////////////////////////////////
// Product presentation Immersive
$product-presentation-immersive-background-color-selected: $color-secondary;
$product-presentation-immersive-background-color-disabled: $color-background;

////////////////////////////////////////
// Product presentation Immersive Tooltip
$product-presentation-immersive-font-size: $font-body-ms-font-size;
$product-presentation-immersive-font-weight: $font-body-xs-font-weight;
$product-presentation-immersive-font-line-height: $font-body-ms-line-height;
$product-presentation-immersive-tooltip-top: rem(-35);
$product-presentation-immersive-tooltip-right: rem(-30);
$product-presentation-immersive-tooltip-top-m: rem(-35);
$product-presentation-immersive-tooltip-right-m: rem(-30);
$product-presentation-immersive-tooltip-content-bottom: rem(64);
$product-presentation-immersive-tooltip-content-top: rem(105);

////////////////////////////////////////
// Section Wrapper
$section-wrapper-primary-background-color: transparent;
$section-wrapper-secondary-background-color: $color-tertiary-1;
$section-wrapper-tertiary-1-background-color: $color-tertiary-1;
$section-wrapper-accessories-background-color: $color-tertiary-1;
$section-wrapper-accessories-background-size: false;
$section-border-radius: $border-radius;
$section-wrapper-primary-color: inherit;
$section-wrapper-secondary-color: $color-on-tertiary-1;
$section-wrapper-tertiary-1-color: $color-on-tertiary-1;
$section-wrapper-accessories-color: $text-color-black;

////////////////////////////////////////
// Section
$section-padding-top-small: rem(20);
$section-padding-bottom-small: rem(40);
$section-margin-bottom-small: rem(24) !important; //36

$section-padding-top-large: rem(55);
$section-padding-bottom-large: rem(32);
$section-margin-bottom-large: rem(48) !important;

$section-margin-small: 0 10.666666666666667% 0 !important;
$section-margin-large: 0 auto 0 !important;

$section-border-color: $color-secondary;

////////////////////////////////////////
// Hotspot
$hotspot-icon-plus-bg: $black;
$hotspot-icon-plus-color: $white;

$hotspot-modal-body-radius: $border-radius;
$hotspot-modal-body-padding: rem(12) rem(24) rem(24) rem(24);
$hotspot-modal-title-margin-bottom: rem(24);
$hotspot-modal-dot-border: 1px solid $color-primary;

$hotspot-modal-custom: true;
$hotspot-modal-custom-content-border-radius: 8px;
$hotspot-modal-custom-close-bg: $white;
$hotspot-modal-custom-close-color: $black;
$hotspot-modal-custom-close-border: 3px solid $black;
$hotspot-modal-custom-border-radius: 50%;
$hotspot-modal-custom-close-position: relative;
$hotspot-modal-custom-close-top: rem(10);
$hotspot-modal-custom-close-left: rem(-10);

$hotspot-modal-custom-close-icon-font-size: rem(26);
$hotspot-modal-custom-close-icon-transform: none;
$hotspot-modal-custom-close-icon-display: block;
$hotspot-modal-custom-padding: 0;
$hotspot-modal-custom-close-height: rem(54);
$hotspot-modal-custom-close-display: none;
////////////////////////////////////////
// Tooltip
////////////////////////////////////////
// Card article
$card-article-bg: $color-background;
$card-article-border-bottom: 0;
// Card article figure
$card-article-figure-bg: $white;
$card-article-figure-border-bottom: 0;
$card-article-figure-border-radius: $border-radius;
$card-article-figure-overflow: hidden;
// Card article category
$card-article-category-bg: $color-background;
$card-article-category-color: $color-on-background;
$card-article-category-padding: rem(4) rem(10) rem(1) rem(10);
$card-article-category-bottom: rem(-20);
$card-article-category-left: 50%;
$card-article-category-right: auto;
$card-article-category-text-transform: unset;
$card-article-category-transform: translate(-50%, -50%);
$card-article-category-text-align: center;
$card-article-category-border-radius: calc(#{$border-radius} / 2);
// Card article date
$card-article-date-after-content: unset;
$card-article-date-after-bg: transparent;
$card-article-date-color: $color-on-background;
// Card article title
$card-article-title-color: $color-secondary;
$card-article-title-color-black: $text-color-black;
// Card article content
$card-article-content-color: $color-on-background;
$card-article-content-padding: rem(20) rem(10);
$card-article-content-text-align: center;
// Card article content after
$card-article-content-after-bg: $color-background;
$card-article-content-after-content: unset;
$card-article-content-after-height: 0;
$card-article-content-after-width: 100%;
$card-article-content-after-left: 0;
$card-article-content-after-top: 0;
$card-article-content-after-bottom: auto;
// Card article hover
$card-article-hover-after-height: 100%;
// Card article content hover
$card-article-content-hover: $color-secondary;
// Card article date hover
$card-article-date-hover-after-bg: transparent;
$card-article-hover-figure-img-transform: scale(1.1);
$card-article-date-hover-color: $color-on-background;
// Card article icon hover
$card-article-hover-icon-display: none;
$card-article-hover-icon-color: transparent;
$card-article-hover-icon-animation: none;

////////////////////////////////////////
// Card article featured
$card-article-featured-content-bg: linear-gradient(
  0deg,
  rgba(0, 0, 0, 0.4) 0%,
  rgba(0, 0, 0, 0) 100%
);
$card-article-featured-content-width: 100%;
$card-article-featured-content-bg-mix-blend-mode: multiply;
$card-article-featured-content-text-align: left;
$card-article-featured-content-padding: rem(20);
$card-article-featured-content-z-index: auto;
$card-article-featured-bottom: 0;
$card-article-featured-figure-wrap-border-radius: 0;

// Card article featured category
$card-article-featured-category-bg: $color-background;
$card-article-featured-category-color: $color-on-background;
$card-article-featured-category-left: rem(20);
$card-article-featured-category-top: rem(-8);
$card-article-featured-category-after: false;
// Card article featured title
$card-article-featured-title-color: $white;
// Card article featured text
$card-article-featured-text-width: 70%;
// Card article featured read more
$card-article-featured-read-more-bg: transparent;
$card-article-featured-read-more-color: $white;
$card-article-featured-read-more-font-size: rem(22);
$card-article-featured-read-more-padding: 0;
$card-article-featured-read-more-right: rem(15);
$card-article-featured-read-more-bottom: rem(15);
// Card article featured  hover
$card-article-featured-hover-figure-img-transform: scale(1.1);

////////////////////////////////////////
// Card promotion
$card-promotion-border-radius: #{$border-radius};
$card-promotion-content-bg: $color-neutro-xlight;
$card-promotion-content-bg-aftersale: $color-surface;
$card-promotion-content-color-aftersale: $black;
$card-promotion-content-border-aftersale:$color-surface;
$card-promotion-content-color: $black;
$card-promotion-content-padding: rem(20);
$card-promotion-img-after: false;
$card-promotion-duration-text-align: left;
$card-promotion-title-text-align: left;

// Card promotion read more
$card-promotion-read-more-bg: transparent;
$card-promotion-read-more-color: $black;
$card-promotion-read-more-font-size: rem(22);
$card-promotion-read-more-padding: rem(6 20 8 8);
$card-promotion-read-more-right: 0;
$card-promotion-read-more-bottom: rem(10);
$card-promotion-cta: false;

////////////////////////////////////////
// carouselV3
$carouselV3-outline-color: $general-outline-color-on-dark;
$carouselV3-title-color: $text-color-white;
$carouselV3-content-color: $text-color-white;
$carouselV3-li-button-color: $text-color-white;
$carouselV3-loader-bg: $color-gray;

////////////////////////////////////////
// LISTING

$listing-header-align: left;

////////////////////////////////////////
// CAROUSELV1
$carousel-product-arrows-align-with-title: true;
$carousel-product-viewall-color: inherit;

////////////////////////////////////////
// Introduction
$introduction-date-after-border-color: $color-primary;
$introduction-social-sharing-color: $color-primary;

////////////////////////////////////////
// FORMS
$form-field-height: rem(34);
$form-field-border-width: rem(0);
$form-field-border-bottom-width: rem(1);
$form-field-border-bottom-color: rgba($color-gray-darker, 1);
$form-field-border-color: rgba($color-gray-darker, 0.3);
$form-field-border-color-focus: $color-primary;
$form-field-padding-vertical: rem(3);
$form-field-padding-horizontal: rem(3);
$form-field-background-color: rgba($color-background, 1);
$form-selected-true-color: $black;
$form-item-label-font-size: 16px;
$form-item-label-font-weight: 400;
$form-item-label-font-style: normal;
$form-item-label-line-height: 28px;
$form-item-error-font-size: 13px;
$form-item-error-font-weight: 400;
$form-item-error-font-style: normal;
$form-item-error-line-height: 15px;
$form-item-error-text-color: #A22028;
$form-checkbox-info-text-font-size: 13px;
$form-checkbox-info-text-font-weight: 400;
$form-checkbox-info-text-font-style: normal;
$form-checkbox-info-text-line-height: 15px;

////////////////////////////////////////
// FORM SELECT PROMO FILTERS
$form-select-promo-border-width: rem(1);
$form-select-promo-border-color: rgba($color-gray-darker, 1);
$form-select-promo-padding-vertical: rem(8);
$form-select-promo-padding-horizontal: rem(8);

////////////////////////////////////////
// FORM INPUT
$input-border-radius: 4px;
$input-text-font-size: 16px;
$input-text-font-style: normal;
$input-text-font-weight: 400;
$input-text-line-height: 28px;
////////////////////////////////////////
// FORM SELECT
$select-border-radius: 4px;
$select-text-font-size: 16px;
$select-text-font-style: normal;
$select-text-font-weight: 400;
$select-text-line-height: 28px;
////////////////////////////////////////
// FORM TEXTAREA
$textarea-border-radius: 4px;
$textarea-text-font-size: 16px;
$textarea-text-font-style: normal;
$textarea-text-font-weight: 400;
$textarea-text-line-height: 28px;
$textarea-height: 112px;
////////////////////////////////////////
// FORM TOOLTIP
$form-tooltip-border-radius: 8px;
$form-tooltip-padding-x: 8px;
$form-tooltip-padding-y: 8px;
$form-tooltip-max-width: 190px;
$form-tooltip-text-font-size: 13px;
$form-tooltip-text-font-style: normal;
$form-tooltip-text-font-weight: 400;
$form-tooltip-text-line-height: 15px;
////////////////////////////////////////
// FORM ACCORDION
$form-accordion-header-font-size: 16px;
$form-accordion-header-font-style: normal;
$form-accordion-header-font-weight: 700;
$form-accordion-header-line-height: 28px;
$form-accordion-content-font-size: 13px;
$form-accordion-content-font-style: normal;
$form-accordion-content-font-weight: 400;
$form-accordion-content-line-height: 15px;
$form-accordion-content-background-color: #F1F1F1;
////////////////////////////////////////
// FORM TITLE
$form-title-font-size: 20px;
$form-title-font-weight: 400;
$form-title-font-style: normal;
$form-title-line-height: 24px;
////////////////////////////////////////
// FORM RADIO
$form-radio-label-font-size: 13px;
$form-radio-label-font-weight: 400;
$form-radio-label-font-style: normal;
$form-radio-label-line-height: 15px;


////////////////////////////////////////
// ICON TEXT
$icon-text-icon-color: $color-on-background;
$icon-text-icon-color-hover: $color-primary;

////////////////////////////////////////
// MODAL PANEL
$promo-blade--bg-color: $color-primary;
$promo-blade--color: $text-color-white;

$modal-bg: $color-background;
$modal-close-bg: $color-primary;
$modal-close-height: rem(54);
$modal-close-width: rem(55);
$modal-close-outline-color: $general-outline-color-on-light;
$modal-close-icon-color: $text-color-white;
$modal-close-label-color: $text-color-white;

////////////////////////////////////////
// LOADING
$loader-border-color: $color-primary;

////////////////////////////////////////
// FLOATING SHORTCUTS MENU
$floating-shortcuts-width: rem(47);
$floating-shortcuts-min-width: rem(160);
$floating-shortcuts-background-color: $color-primary;
$floating-shortcuts-menu-border-radius: rem(32);
$floating-shortcuts-menu-background-color: rgba(56, 56, 56, 0.6);
$floating-shortcuts-menu-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.3);
$floating-shortcuts-menu-backdrop-filter: rem(12);
$floating-shortcuts-button-border-radius: rem(25);
$floating-shortcuts-button-background-color: $color-background;
$floating-shortcuts-button-background-color-hover: $color-primary;
$floating-shortcuts-button-title-font-family: $font-body;
$floating-shortcuts-button-title-color: $black;
$floating-shortcuts-button-title-color-hover: $white;
$floating-shortcuts-button-title-font-size: $font-heading-ms-font-size;
$floating-shortcuts-button-title-font-weight: $font-heading-ms-font-weight;
$floating-shortcuts-button-title-line-height: $font-heading-ms-line-height;
$floating-shortcuts-button-title-font-style: normal;
$floating-shortcuts-button-title-letter-spacing: $font-body-letter-spacing;
$floating-shortcuts-button-title-text-transform: capitalize;
$floating-shortcuts-button-icon-arrow-color: rgba(56, 56, 56, 0.5);
$floating-shortcuts-button-icon-arrow-font-size: $font-heading-ms-font-size;
$floating-shortcuts-button-icon-arrow-font-weight: $font-heading-ms-font-weight;
$floating-shortcuts-action-menu-button-border-radius: rem(30);
$floating-shortcuts-action-menu-button-background-color: $color-primary;
$floating-shortcuts-close-icon-font-size: rem(26);
$floating-shortcuts-close-icon-color: $color-background;
$floating-shortcuts-close-icon-font-weight: 700;
$floating-shortcuts-close-button-font-size: $font-heading-ms-font-size;
$floating-shortcuts-close-button-color: $white;

////////////////////////////////////////
// EDITORIAL ACCORDION
$accordion-header-border-color: $color-gray-lightest;

///////////////////////////////////////
// WALL IMAGE
$wall-image-border-radius: rem(8);

////////////////////////////////////////
// GIGYA COMMUNITY
$gigya-login-icon: url('../../assets/img/vespa/ico-login.svg');
$gigya-logged-icon: url('../../assets/img/vespa/ico-logged.svg');

////////////////////////////////////////
// CARD BIG
$card-big-event-category-font-size: $font-heading-xxs-font-size;
$card-big-event-category-font-style: normal;
$card-big-event-category-font-weight: $font-heading-xxs-font-weight;
$card-big-event-category-text-transform: uppercase;
$card-big-event-category-line-height: $font-heading-xxs-line-height;
$card-big-event-category-background-color: $color-surface;
$card-big-event-category-underline: unset;
$card-big-event-category-text-color: $color-surface;
$card-big-event-category-padding: rem(3) 0;
$card-big-font-family: $font-heading;
$card-big-heading-font-size: $font-heading-xl-font-size-small;
$card-big-heading-font-size-mobile: $font-heading-l-font-size-small;
$card-big-heading-font-weight: $font-heading-xl-font-weight;
$card-big-heading-font-weight-mobile: $font-heading-xl-font-weight;
$card-big-heading-font-style: $font-heading-xl-font-style;
$card-big-heading-line-height: rem(43);
$card-big-heading-line-height-mobile: rem(33);
$card-big-heading-letter-spacing: -0.05em !important;
$card-big-paragraph-font-size: $font-heading-xs-font-size;
$card-big-paragraph-font-size-mobile: $font-heading-xs-font-size;
$card-big-paragraph-font-weight: $font-heading-xs-font-weight;
$card-big-paragraph-font-weight-mobile: $font-heading-xs-font-weight;
$card-big-paragraph-letter-spacing: -0.05em !important;
$card-big-paragraph-font-style: $font-heading-xs-font-style;
$card-big-paragraph-line-height: $font-heading-ms-line-height;
$card-big-button-font-size: rem(18);
$card-big-button-font-weight: $font-heading-ms-font-weight;
$card-big-button-font-style: normal;
$card-big-button-letter-spacing: $card-big-paragraph-letter-spacing;
$card-big-button-text-transform: unset;

////////////////////////////////////////
// CARD-SMALL
$card-small-event-category-font-size: $font-heading-xxs-font-size;
$card-small-event-category-font-style: normal;
$card-small-event-category-font-weight: $font-heading-xxs-font-weight;
$card-small-event-category-text-transform: uppercase;
$card-small-event-category-line-height: $font-heading-xxs-line-height;
$card-small-event-category-background-color: $color-surface;
$card-small-event-category-underline: unset;
$card-small-event-category-text-color: $color-secondary;
$card-small-event-category-padding: rem(3) 0;
$card-small-font-family: $font-body;
$card-small-heading-font-size: $font-heading-l-font-size-large;
$card-small-heading-font-size-mobile: $font-heading-ml-font-size-small;
$card-small-heading-font-weight: $font-heading-l-font-weight;
$card-small-heading-font-weight-mobile: $font-heading-l-font-weight;
$card-small-heading-font-style: $font-heading-l-font-style;
$card-small-heading-line-height: rem(43);
$card-small-heading-line-height-mobile: rem(33);
$card-small-heading-letter-spacing: -0.05em !important;;
$card-small-button-underline-color: $white;
$card-small-button-font-size: rem(18);
$card-small-button-font-weight: $font-heading-ms-font-weight;
$card-small-button-font-style: normal;
$card-small-button-letter-spacing: $card-small-heading-letter-spacing;
$card-small-button-text-transform: uppercase;

////////////////////////////////////////
// CAROUSEL-VEHICLE-PREMIUM
$font-displacements-font-size: 14px;
$font-displacements-line-height: 10px;
$font-displacements-icon-size: 10px;
$displacement-btn-padding: 10px 15px;
$displacement-btn-weight: 400;

.editorial-icon {
  margin-top: rem(30);
  @include breakpoint (large) {
    margin-top: 0;
  }
  .icon-text__title {
    font-size: rem(28) !important;
  }
  display: flex;
  flex-direction: column;
  //justify-content: space-between;

  .editorial-icon__cta {
    position: sticky;
    top: 100%;
  }
  .icon-text__title {
    line-height: normal !important;
  }
}


.section__container {
  margin-left: 0;
  margin-right: 0;
  max-width: none;
  .grid-x.align-center {
    display: flex;
    padding-bottom: 0 !important;
    padding-top: 0;
    margin-bottom: rem(40);
    @include breakpoint(large) {
      padding-top: rem(60);
      margin-bottom: rem(60);
    }
  }
}



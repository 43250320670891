
.form-select {
  position: relative;
  display: block;

  &::after {
    content: "";
    width: rem(7);
    height: rem(7);
    border-top: rem(2) solid $form-field-border-bottom-color;
    border-right: rem(2) solid $form-field-border-bottom-color;
    display: inline-block;
    transform: rotate(135deg);
    position: absolute;
    top: rem(11);
    right: rem(10);
  }

  .custom-select{
    -webkit-appearance: none;
    @include font-body-s;
    appearance: none;
    background-color: $form-field-background-color;
    border-bottom: $form-field-border-bottom-width solid $form-field-border-bottom-color;
    border-left: $form-field-border-width solid $form-field-border-color;
    border-right: $form-field-border-width solid $form-field-border-color;
    border-top: $form-field-border-width solid $form-field-border-color;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    min-height: $form-field-height;
    padding: $form-field-padding-vertical rem(35) $form-field-padding-vertical $form-field-padding-horizontal;
    width: 100%;

    &:focus {
      border-color: $form-field-border-color-focus;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }

    &.is-invalid{
      border-color: $color-error;
      border-width: .2rem;

      &:focus{
        outline-color: $color-error;
      }

    }

    /*&.is-valid{
      border-color: green;
      border-width: .2rem;

      &:focus{
        outline-color: green;
      }

    }*/
  }

}


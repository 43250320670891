.editorial__title.text--heading-m {
  line-height: normal;
  @include breakpoint(large){
    line-height: rem(52);
  }
}
.icon-text .icon-text__title {
  line-height: normal;
  @include breakpoint(large){
    line-height: rem(24);
    font-size: rem(14);
  }
}
.tabs {
  margin-bottom: rem(32);
  @include breakpoint(large){
    margin-bottom: rem(40);
  }
}




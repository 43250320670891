.swatches-color {
  &.vespa {
    .swatches-color__icon,
    .swatches-color__darken {
      font-size: rem(20);
      line-height: rem(20);
      padding: 0 3px;
    }
    .swatches-color__active {
      font-size: rem(30);
      line-height: $swatches-color-icon-size;
      top: unset;
    }
    .swatches-color__inactive {
      @include cover;
      font-size: rem(30);
      line-height: $swatches-color-icon-size;
      color: $swatches-inactive-color;
      top: unset;
    }
  }
}
.product-presentation.immersive {
  .swatches-color {
    &.vespa {
      .swatches-color__icon,
      .swatches-color__darken {
        line-height: rem(32) !important;
      }
    }
  }
}

.editorial {
  &__title {
    padding: 0 !important;
  }

  &__text > * {
    padding: 0 !important;
  }

  &__subheading--upper {
    font-weight: $font-heading-ms-font-weight;
  }

  &__cta {
    margin-top: rem(24) !important;
    margin-bottom: 0 !important;
    .button {
      margin-bottom: rem(20) !important;
      margin-right: rem(16) !important;
      @include breakpoint(medium) {
        margin-top: 0 !important;
      }
    }
    span:last-child .button {
      margin-bottom: 0 !important;
    }

    span + span {
      padding-top: 0px !important;
    }
  }
}

////////////////////////////////////////
// breakpoint Large
@include breakpoint(large) {
  ////////////////////////////////////////
  // editorial
  .editorial {

    .editorial__content,
    .editorial__figure {
      @include xy-cell(6);
    }

    ////////////////////////////////////////
    // image Small
    &--image-small {
      .editorial__content {
        @include xy-cell(8);
      }

      .editorial__figure {
        @include xy-cell(4);
      }
    }

    ////////////////////////////////////////
    // image Medium
    &--image-medium {
      .editorial__content,
      .editorial__figure {
        @include xy-cell(6);
      }
    }

    ////////////////////////////////////////
    // image Large
    &--image-large {
      .editorial__content {
        @include xy-cell(4);
      }

      .editorial__figure {
        @include xy-cell(8);
      }
    }

    ////////////////////////////////////////
    // align Center && align 2 col
    &--align-center,
    &--align-center-two-col {

      .editorial__content,
      .editorial__figure {
        @include xy-cell(12);
        margin: 0 auto;
      }
    }

    &--align-center-two-col {
      .editorial__content,
      .editorial__figure {
        @include xy-cell(12);
      }
    }
  }
}
.card-big {
  border-radius: rem(8);
  &__picture {
    .image {
      border-radius: rem(8);
    }
    .card-big {
      &__gradient {
        border-radius: rem(8);
      }
    }
  }
}
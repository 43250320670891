.accordion__title {
  @include breakpoint(large){
    font-size: rem(24);
  }
}
.accordion {
  @include breakpoint(large){
    padding-top: rem(40);
  }
  @include breakpoint(small) {
    padding: rem(24) 0 rem(40) 0;
  }
}
.gallery {
  .slide-img__image, .slide-img__image img {
    border-radius: $gallery-border-radius-v2;
  }
  .section__container--full {
    .slide-img__image, .slide-img__image img {
      border-radius: 0;
    }
  }
  .gallery__nav-button {
    opacity: 1 !important;
  }

  .hooper-pagination {

    .hooper-indicator {

      &::after {
        // safe touch area
        background-color: $white;
        opacity: 0.5;
      }

      &.is-active {

        &::after {
          background-color: $white;
          opacity: 1;
        }
      }
    }
  }

  .gallery__container {
    &:not(.full-width) {
      .hooper-list {
        border-radius: $border-radius;
      }
    }
  }
  .icon.icon-arrow-dx {
    width: 48px;
    height: 48px;
    font-size: 18px;
    padding-left: 16px;
  }
  .icon.icon-arrow-sx {
    width: 48px;
    height: 48px;
    font-size: 18px;
    padding-right: 16px;
  }
  .gallery__nav-button--next {
    transform: translate(-16px, -50%);
    width: 48px;
  }
  .gallery__nav-button--prev {
    transform: translate(16px, -50%);
    width: 48px;
  }
}
.family-showcase-container {
  .title {
    font-weight: normal;
    letter-spacing: -0.05em !important;
  }

  .family-showcase{
    /* desktop */
    @media screen and (min-width: 640px) {
      .swiper {
        .swiper-gradient {
          &.left {
            left: 0;
            background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
          }
          &.right {
            right: 0;
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
          }
          width: rem(115);
          height: 100%;
          position: absolute;
          z-index: 2;
        }
      }
    }
  }
}
.navigation__highlights-v2 {
  font-family: $font-body;
  padding: 0 rem(15);
  @include breakpoint(large) {
    padding: 0 rem(24) rem(24);
    display: flex;
    justify-content: center;
  }
  .card-highlight-v2 {
    margin-bottom: rem(15);
    cursor: pointer;
    @include breakpoint(large) {
      margin: 0 rem(15);
    }
    .card-highlight-container {
      width: 100%;
      height: rem(240);
      position: relative;
      display: inline-block;
      @include breakpoint(large) {
        width: rem(350);
        height: rem(242);
        overflow: hidden;
      }
      .card-highlight-v2__img {
        width: 100%;
        height: 100%;
        position: relative;
        &.overlay-active::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%) no-repeat;
          cursor: pointer;
          @include breakpoint(large) {
            z-index: 1;
          }
        }
        @include breakpoint(large) {
          transform: scale(1);
          transition: 500ms;
          &:hover {
            transform: scale(1.2);
            transition: 500ms;
          }
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .card-highlight-v2__content {
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: rem(16);
        left: rem(16);

        .card-highlight__text {
          color: $card-highlights-v2-text-font-color;
          font-family: $font-body;
          font-size: $card-highlights-v2-text-font-size;
          font-style: $card-highlights-v2-text-font-style;
          font-weight: $card-highlights-v2-text-font-weight;
          line-height: $card-highlights-v2-text-line-height;
        }

        a {
          width: fit-content;
          background-color: rgba(255, 0, 0, 1);
          padding: rem(12) rem(16);
          margin-top: rem(20);
          text-decoration: none;
          cursor: pointer;
          .card-highlight__cta {
            font-family: $card-highlights-v2-font-family;
            color: $card-highlights-v2-cta-font-color;
            font-size: $card-highlights-v2-cta-font-size;
            font-style: $card-highlights-v2-cta-font-style;
            font-weight: $card-highlights-v2-cta-font-weight;
            line-height: $card-highlights-v2-cta-line-height;
            text-transform: uppercase;
            display: inline;
          }
          i {
            display: none;
          }
        }
      }
    }
  }

  //GuideStyle for Accessory menu
  .card-highlight-v2.outlined {
    .card-highlight-v2__content {
      a {
        .card-highlight__cta {
          font-family: $card-highlights-v2-cta-outlined-font-family;
          color: $card-highlights-v2-cta-outlined-font-color;
          font-size: $card-highlights-v2-cta-outlined-font-size;
          font-style: $card-highlights-v2-cta-outlined-font-style;
          font-weight: $card-highlights-v2-cta-outlined-font-weight;
          line-height: $card-highlights-v2-cta-outlined-line-height;
          text-transform: unset;
          display: inline;
        }
        background-color: unset;
        padding: 0;
        margin-top: 0;
        text-decoration: none;
        i {
          display: inline-block;
          color: $card-highlights-v2-cta-font-color;
          width: rem(16);
          height: rem(16);
          margin: auto;
          margin-left: rem(5);
          &::before {
            margin: auto;
            font-size: $card-highlights-v2-cta-icon-font-size;
          }
        }
      }
    }
  }
}
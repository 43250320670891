.header-v2 {

  .header__logo {
    .header__logo__on-background,
    .header__logo__on-tranparent {
      padding: $space-size-s 15px;
      @include breakpoint(large) {
        padding: $space-size-s 40px;
      }
    }
  }

  .header__menu-services {
    .header__menu-services__nav {
      margin: 0 3px;
      @include breakpoint(large) {
        margin: 0 28px 0 $space-size-s;
      }
    }
  }
}
.listing-header {
  div {
    width: 100%;
    .listing-header__heading {
      text-align: $listing-header-align;
    }
  }

  &.listing-no-title {
    flex-direction: row-reverse;
    padding-right: 0;
  }
}
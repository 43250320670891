.wall-image {
  .swiper-button-close,
  .swiper-button-prev,
  .swiper-button-next {
    border-radius: 100% !important;
    background-color: $white;
    i {
      color: $black;
    }
  }
  .swiper-button-close {
    border: solid 3px black;
    .icon-plus:before {
      font-weight: bold !important;
    }
  }
  .gallery-image-container {
    padding: 30px;
  }
  .swiper-button-close {
    margin: 30px 30px 0 0;
  }

  .paired-image, .single-image, .image-container {
    border-radius: $wall-image-border-radius;
  }

  .swiper-pagination-bullet {
    width: rem(12);
    height: rem(12);
    opacity: 0.5;
    &-active {
      opacity: 1;
      background-color: $color-background;
    }
  }
}
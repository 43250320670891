.section.section-editorial-immersive {
  padding: 0!important;
  .editorial-immersive__align-left, .editorial-immersive__align-right {
    padding: rem(16) 0 !important;
    @include breakpoint(large) {
      padding-top: rem(60) !important;
      padding-bottom: rem(60) !important;
    }

    &.editorial-immersive__grid-fullImage {
      padding: 0!important;
    }
  }
}
.editorial-immersive__grid {
  .editorial-immersive__figure {
    .image {
      border-radius: 8px;
    }
  }
}

.editorial-immersive__grid-fullImage {
  .editorial-immersive__figure {
    padding: 0;

    .image {
      border-radius: 0;
    }
  }
}
.hooper-pagination {

  .hooper-indicator {

    &::after {
      height: rem(12) !important;
      width: rem(12) !important;
      margin: rem(10) rem(4) !important;
      // safe touch area
      background-color: $white;
      opacity: 0.5;
    }

    &.is-active {

      &::after {
        background-color: $white;
        opacity: 1;
      }
    }
  }
}

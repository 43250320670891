.card-article__title {
  color: $card-article-title-color-black;
  transition: none;
}
a.card-article__link:hover,
a.card-article__link:focus {

  .card-article__title {
    color: $card-article-title-color-black;
  }
}


.card-article--featured__title {
  color: white !important;
}
.card-article__fetured {
  img {
    border-radius: 8px;
    }
  }
.card-article--featured__figure-wrap {
  border-radius: 8px;
}
.card-article--featured__category,
.card-article__category {
  border-top-right-radius: calc(0.8rem/2);
}

.card-article__figure {
  &-wrap {
    border-top-left-radius: calc(0.8rem/2);
    border-top-right-radius: calc(0.8rem/2);
  }
}

#uuid-c1901d83-2848-4bfa-a1ca-1410f15ac9a3 {
  padding-bottom: rem(48);
  @include breakpoint(large) {
    padding-bottom: rem(60);
  }
}

.hooper-list {
  .button.button--text.button--medium:after {
    display: none;
  }
}

.card-article {
  .icon-text__title {
    border-bottom: solid 1px;
  }
}

.product-presentation {
  .product-presentation__abstract {
    margin: 2rem 0 2rem !important;
  }

  .product-presentation__price {
    padding-top: 20px;
    width: fit-content !important;
    margin: auto !important;
    @include breakpoint(large) {
      width: auto !important;
      margin: unset !important;
    }
    margin-top: 2rem !important;
  }

  .hooper-slide.is-active.is-current {
    position: relative;
    margin-bottom: rem(20);
    .hooper-overlay {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      width: 50%;
      height: 100%;
      opacity: 0.2;
      border-radius: rem(8);
      z-index: -1;
      transform: translateX(-50%);
    }
  }

  .product-presentation_colors {
    border-bottom: 1px solid $product-presentation-border-bottom;
    padding-bottom: rem(20);

    .product-presentation_colors__current-color {
      text-transform: capitalize !important;
      font-weight: 700 !important;
      font-size: 12px !important;

      span {
        text-transform: lowercase !important;
        font-weight: 500 !important;
        font-size: 12px !important;
      }

      span::first-letter {
        text-transform: uppercase !important;
      }
    }
    .product-presentation_colors__current-color::first-letter {
      text-transform: uppercase !important;
    }
  }

  .product-presentation_colors__swatches {
    .custom-swatches-color {
      display: flex;
      gap: rem(18);
      margin: rem(15);
      .color-icon {
        width: rem(20);
        height: rem(20);
        border-radius: 100%;
        cursor: pointer;
        outline: solid 1px black;
        outline-offset: rem(4);
      }
    }
  }

  ////////////////////////////////////////
  // price
  .price {
    color: $text-color-black;
    font-family: $font-heading-m-font-family;
    font-size: $font-heading-m-font-size-large;
    font-weight: $font-heading-m-font-weight;
    line-height: $font-heading-m-line-height-large;
    font-style: $font-heading-m-font-style;

    text-align: center;

    @include breakpoint(large) {
      text-align: left;
    }

    .price-info {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background-color: $color-primary;
      position: absolute;
      top: 0;
      right: -30px;
    }
  }

  .product-presentation__price {
    margin: 10px 0 0 0;
    position: relative;
  }

  .product-price {
    .product-price__discounted {
      @include font-heading-l;
      white-space: nowrap;
      position: relative;
      display: inline-block;
    }

    .product-price__list {
      @include font-heading-l;
      white-space: nowrap;
      display: block;

      .icon-divider {
        display: none;
      }

      @include breakpoint(large) {
        position: relative;
        display: inline-block;
      }
    }

    .product-price__tooltip-vespa {
      position: absolute;
      right: -30px;
      top: -10px;

      i[class^="icon-"] {
        color: $color-primary;
        text-decoration: none;
      }

      @include breakpoint(large) {
        bottom: auto;
        transform: none;
      }
    }

    .product-price__discounted + .product-price__list {
      text-decoration: line-through;

      @include font-body;
      margin-right: $space-size-xs * 2;
      position: relative;
      display: inline-block;

      @include font-heading-s;
      margin-left: $space-size-xs * 2;

      .icon-divider {
        display: inline-block;
        @include center(y);
        right: $product-presentation-price-divider-right;

        color: $color-primary;
        font-size: $product-presentation-price-divider-size;
        line-height: $product-presentation-price-divider-size;

        @include breakpoint(large) {
          position: absolute;
          left: $product-presentation-price-divider-right;
          right: auto;
        }
      }
    }

    .product-price__planned {
      @include font-body;
      white-space: nowrap;

      @include breakpoint(large) {
        display: block;
      }
    }
  }
}
.nav-tabs-styled {
  .nav-tabs {
    @include xy-cell(12);

    .nav-link {
      &.active {
        .icon-text__title {
          font-weight: $editorial-tabs-na-link-active-title-font-weight-bold !important;
        }
      }
      &.active:after {
        background-color: $editorial-tabs-nav-link-active-border-color-black;
      }
    }
  }

  &.tab-content {
    padding-top: rem(24) !important;
    @include breakpoint(large) {
      padding-top: rem(40) !important;
    }

    .editorial__content {
      padding-top: rem(30) !important;
      @include breakpoint(large) {
        padding-top: 0 !important;
      }
    }
  }

  @include breakpoint(large) {
    .nav-tabs {
      @include xy-cell(12);
    }
  }

  &.tab-content {
    padding-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    @include breakpoint(medium) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

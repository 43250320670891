.container-scf {
  --scf-box-shadow-dark-color: #f1f1f1;
  --scf-box-shadow-light-color: #f1f1f1;
  --scf-bg-color: #fff;
  --scf-bg-secondary-color: #fff;
  --scf-border-color: #acccce;
  --scf-error-color: #b00020;
  --scf-success-color: green;
  --scf-button-border-radius: 0;
  --scf-default-font-family: "Inter,sans-serif,Arial,Helvetica";
  --scf-title-font-family: "Inter,sans-serif,Arial,Helvetica";
  --scf-title-font-weight: 700;
  --scf-title-transform: capitalize;
  --scf-modal-border-radius: 0;
  --scf-wc-brand-primary-color: #000;
  --scf-wc-default-text-color: #000;
  --scf-brand-primary-color: #000;
  --scf-default-text-color: #000;
}
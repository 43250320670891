.product-presentation.immersive {
  .container-left {
    @include breakpoint(large) {
      max-height: rem(750);
    }
  }
  margin-right: 0;
  * {
    letter-spacing: $font-heading-l-letter-spacing;
  }

  .container-title {
    @include breakpoint(large) {
      margin-bottom: rem(8);
    }
    .title {
      font-size: $font-heading-l-font-size-small;
      @include breakpoint(large) {
        font-size: $font-heading-l-font-size-large;
      }
    }
  }

  .product-presentation__price {
    .product-price-immersive__tooltip {
      bottom: auto;
      left: auto;
      right: $product-presentation-immersive-tooltip-right-m;
      top: $product-presentation-immersive-tooltip-top-m;
      @include breakpoint(medium) {
        right: $product-presentation-immersive-tooltip-right;
        top: $product-presentation-immersive-tooltip-top;
        transform: none;
      }
    }
    .tooltip__content {
      border-radius: rem(4);
    }
    margin-top: rem(15) !important;
    @include breakpoint(large) {
      margin: unset !important;
    }
  }

  .product-presentation__colors {
    padding-top: rem(14);
    @include breakpoint(large) {
      padding-top: 0;
    }
  }

  .product-price {
    padding: 0;
    @include breakpoint(large) {
      line-height: 1;
    }
    .product-price__discounted {
      @include breakpoint(large) {
        line-height: 1;
      }
      font-size: rem(16);
    }
    .product-price__list {
      position: relative;
      display: inline-block;
      line-height: 0;
      font-size: rem(24) !important;
    }
  }

  .breadcrumb__back {
    left: rem(3) !important;
    @include breakpoint(large) {
      left: rem(55) !important;
    }
    .icon-arrow-sx {
      font-size: rem(17) !important;
    }
  }

  .product-presentation-container {
    .container-right {
      padding: 0;
      @include breakpoint(large) {
        width: 40%;
        margin-top: 0;
      }
      .container {
        @include breakpoint(large) {
          width: 90%;
          padding-left: 10%;
        }
        @media screen and (min-width: 1366px) {
          width: 80%;
          padding: 0 10%;
        }
      }
      .pdp-cta-links {
        width: fit-content;
      }
    }
    .container-left {
      @include breakpoint(large) {
      width: 60%;
      }
      .back-alignment {
        .button {
          padding-left: 0;
          i {
            display: none;
          }
          &::after {
            display: none;
          }
        }
      }

      .product-image {
        &.translate-origin {
          @include breakpoint(large) {
            transform: translateX(var(--viewport-value));
          }
          @include breakpoint(xlarge) {
            transform: translateX(var(--viewport-value)) translateY(-10px);
          }
        }
        &.placeholder {
          height: auto;
          overflow: hidden;
        }
      }
    }
  }

  .text-xs-upper,
  .color-name-xs {
    font-size: rem(12);
    text-transform: capitalize !important;
  }

  .product-presentation__colors {
    .color-name-xs {
      font-weight: 700;
    }
  }

  .container-setup-over {
    .vehicle {
      .text-xs-upper {
        font-weight: 700;
      }
    }
  }

  .container-right {
    .content-right {
      .text-xs-upper {
        margin: rem(24) auto rem(8);
        @include breakpoint(large) {
          margin: rem(48) 0 rem(8);
        }
      }
    }
  }

  //Displacements Mobile
  .right-info-container {
    padding: 0;
    .text-center-mobile {
      margin: rem(20) 0 rem(8) 0;
      padding: 0;
    }
    .swiper {
      margin: 0;
      .vehicle.selected {
        box-shadow: unset;
      }
    }
    .container-swiper-vehicle-image {
      .text-xs-upper {
        font-size: rem(14);
        font-weight: 700;
      }
    }

    .show-setup-button {
      .text-xs-upper {
        font-size: $font-button-m-font-size !important;
        font-weight: $font-heading-ml-font-weight;
      }
    }
  }

  .container-displacement {
    .button-displacement-container {
      max-height: rem(44);
      .displacement {
        font-size: rem(14) !important;
        border-radius: rem(8);
        font-weight: 700;
        height: rem(44) !important;
        text-transform: lowercase;
        background-color: $product-presentation-immersive-background-color-disabled;
        &.disabled {
          background-color: $product-presentation-immersive-background-color-disabled;
        }
        &.selected {
          box-shadow: unset !important;
          background-color: $product-presentation-immersive-background-color-selected;
        }
      }
    }
  }

  .container-setup-over {
    .container-setup {
      .vehicle {
        padding: 12px 0 14px;
        border-radius: rem(8);
        position: relative;
        .text-xs-upper {
          font-size: rem(14);
        }
        &.selected {
          box-shadow: unset;
        }
        &.selected::after {
          content: "";
          position: absolute;
          background-color: $product-presentation-immersive-background-color-selected;
          border-radius: rem(8);
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
        }
        img {
          z-index: 10;
        }
        span {
          margin: 11px auto 0;
          z-index: 10;
          position: relative;
        }
      }
    }
  }
  .right-info-container {
    .swiper-wrapper {
      .swiper-slide {
        border-radius: rem(8);
        &.selected.swiper-slide-active {
          position: relative;

          .container-swiper-vehicle-image {
            img, .text-xs-upper {
              position: relative;
              z-index: 10;
            }

            &::after {
              content: "";
              position: absolute;
              background-color: $product-presentation-immersive-background-color-selected;
              border-radius: rem(8);
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 1;
            }
          }
        }
      }
    }

    .show-setup-button {
      margin-bottom: rem(8);
      .text-xs-upper {
        text-decoration: underline;
        font-size: rem(10);
      }
    }
  }

  @include breakpoint(large) {
    @keyframes transition-onload {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(-(var(--viewport-value)));
      }
    }
  }

  @include breakpoint(xlarge) {
    @keyframes transition-onload {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(var(--viewport-value)) translateY(-10px);
      }
    }
  }
}

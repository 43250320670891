.modal .hotspot__modal-dialog .hotspot__modal-title {
  margin-top: 0 !important;
  margin-bottom: rem(12) !important;
  line-height: normal;
}
.modal .hotspot__modal-dialog .hotspot__modal-content {
  line-height: rem(24);
}
.show-for-sr-visible {
  @include font-heading-l;
}
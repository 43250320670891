/**
 * Design System for Piaggio Group
*/
@import "abstracts";

// Brand specific vars
@import "tokens/tokens-vespa";

// Vendor specific variables
@import "vendors/vendors";

@import "html"; //temporary style depending on html data

.theme-vespa {
  @import "typography/var_icons-vespa";
  @import "typography/santander_custom_vespa";
  @import "main";
  @import "components/custom-vespa/editorial-immersive";
  @import "components/custom-vespa/wall-image";
  @import "components/custom-vespa/player-video";
  @import "components/custom-vespa/family-showcase";
  @import "components/custom-vespa/hub-links";
  @import "components/custom-vespa/tabs";
  @import "components/custom-vespa/editorial";
  @import "components/custom-vespa/product-presentation";
  @import "components/custom-vespa/gallery";
  @import "components/custom-vespa/card-product";
  @import "components/custom-vespa/hooper";
  @import "components/custom-vespa/footer";
  @import "components/custom-vespa/swatches";
  @import "components/custom-vespa/breadcrumb";
  @import "components/custom-vespa/header";
  @import "components/custom-vespa/header-v2";
  @import "components/custom-vespa/section";
  @import "components/custom-vespa/product-presentation-immersive";
  @import "components/custom-vespa/hero-heading";
  @import "components/custom-vespa/menu-anchor";
  @import "components/custom-vespa/editorial-icon";
  @import "components/custom-vespa/button";
  @import "components/custom-vespa/card-article";
  @import "components/custom-vespa/dealer-locator";
  @import "components/custom-vespa/listing-header";
  @import "components/custom-vespa/listing";
  @import "components/custom-vespa/accordion";
  @import "components/custom-vespa/editorial-cover-image";
  @import "components/custom-vespa/editorial-tabs";
  @import "components/custom-vespa/editorial-two-columns";
  @import "components/custom-vespa/hotspot";
  @import "components/custom-vespa/carousel-product";
  @import "components/custom-vespa/card-big";
  @import "components/custom-vespa/card-small";
  @import "components/custom-vespa/card-promotion";
  @import "components/custom-vespa/card-promotion-small";
  @import "components/custom-vespa/editorial-quote";
  @import "components/custom-vespa/gigya-app";
  @import "components/custom-vespa/hero-single-content";
  @import "components/custom-vespa/cards-wrapper";

}

.menu-anchor {
  .menu-anchor__item {
    font-size: 14px;
  }
  li.hooper-slide.is-current-slide {
    a {
      font-weight: 700;
    }
  }
  li.hooper-slide.is-current-slide::after {
    border-bottom: 2px solid $color-primary !important;
    width: 100%;
  }
}
.menu-anchor:after {
  border-bottom: 0px !important;
}
.menu-anchor.is-fixed {
  background: rgba(255, 255, 255, 0.70) !important;
  backdrop-filter: blur(20px);
  .hooper-prev {
    background: transparent !important;
    backdrop-filter: none !important;
  }
  .hooper-next {
    background: transparent !important;
    backdrop-filter: none !important;
  }
}
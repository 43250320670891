.navigation-v2__content-nested-overlay {
  display: none;

  &.visible {
    @include breakpoint(large) {
      display: block;
      content: "";
      position: fixed;
      z-index: 100;
      top: rem(70);
      left: 0;
      width: 100%;
      max-width: rem(1920);
      height: 100vh;
      height: 100vhs;
      background-color: $main-navigation-item-v2-overlay-visible-bg-color;
      backdrop-filter: blur(24px);
      //Add translate for width > 1920;
      transition: 200ms;
      //Add Animation on nested-open
    }
  }
}

.navigation-v2__menu-nested {
  display: none;
  position: absolute;
  transform: translateX(100%);
  background-color: $main-navigation-item-v2-menu-nested-background-color;
  width: 100%;
  height: -webkit-fill-available;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: scroll;
  animation: close-modal 600ms forwards;

  //TABLET ADAPTATION
  @media screen and (min-width: 641px) and (max-width: 1025px) {
    height: -webkit-fill-available;
    width: 50%;
    left: 50%;
  }

  @include breakpoint(large) {
    position: unset;
    overflow: hidden;
    margin: 0 auto;

    &:not(.open) {
      height: 0;
      animation: close-modal-nav 600ms forwards;
      transition: height 600ms;
    }
  }

  &.open {
    display: block;
    transform: translateX(0);
    animation: open-modal 600ms forwards;

    @include breakpoint(large) {
      height: var(--multiply-height);
      display: flex;
      margin: 0 auto;
      animation: open-modal-nav 600ms forwards;

      .product-list-nested {
        width: 100%;
      }
    }
  }

  .navigation-v2__menu-nested__back {
    padding: rem(32) rem(20);
    position: sticky;
    background-color: $main-navigation-item-v2-menu-nested-background-color;
    top: 0;
    overflow: hidden;
    z-index: 100;
    @media screen and (min-width: 641px) and (max-width: 1025px) {
      display: none;
    }
    &.contact-us {
      i {
        font-size: $main-navigation-item-v2-menu-nested-back-button-font-size !important;
        color: $main-navigation-item-v2-menu-nested-back-button-text-color !important;
        width: rem(16) !important;
        height: rem(16) !important;
        margin-right: rem(12);
      }
    }

    h2 {
      font-size: $main-navigation-item-v2-menu-nested-back-h2-font-size;
      color: $main-navigation-item-v2-menu-nested-back-button-text-color !important;
      font-weight: $main-navigation-item-v2-menu-nested-back-h2-font-weight;
    }

    i {
      font-size: $main-navigation-item-v2-menu-nested-back-icon-font-size;
      color: $main-navigation-item-v2-menu-nested-back-icon-color!important;
      width: rem(16);
      height: rem(16);
      margin-right: rem(12);
    }

    @include breakpoint(large) {
      display: none;
    }
  }

  .navigation__menu-nested-container {
    @media screen and (min-width: 641px) and (max-width: 1025px) {
      margin-top: rem(20);
    }
    @include breakpoint(large) {
      width: fit-content;
      height: fit-content;
      margin: auto;
      display: flex;
      padding: rem(24) 0;
    }
    .navigation__menu-nested__menu {
      @include breakpoint(large) {
        display: inline-block;
      }
      ul {
        position: relative;
        padding: 0 rem(20) rem(20) rem(20);
        @include breakpoint(large) {
          display: flex;
          flex-direction: column;
          max-height: rem(210);
          width: rem(540);
          flex-wrap: wrap;
          padding-bottom: rem(34);
          &.small {
            max-height: rem(120);
          }
          &.medium {
            max-height: rem(160);
          }
          &.long {
            max-height: rem(210);
          }
          &.x-long {
            max-height: rem(240);
          }
        }

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: rem(20);
          width: 90%;
          border-bottom: 1px solid rgba(233, 233, 233, 1);
          //TABLET ADAPTATION
          @media screen and (min-width: 641px) and (max-width: 1025px) {
            left: rem(20);
          }
          @include breakpoint(large) {
            opacity: 0;
          }
        }

        &.view-all-active::after {
          @include breakpoint(large) {
            opacity: 1;
            width: 100%;
            left: rem(10);
          }
        }

        li {
          text-align: left;
          position: relative;
          padding: rem(16) 0;
          @media screen and (min-width: 641px) and (max-width: 1025px) {
            text-align: center;
          }
          @include breakpoint(large) {
            padding: rem(12) 0;
            &::after {
              position: absolute;
              content: "";
              border-bottom: 1px solid rgba(236, 57, 53, 1);
              width: rem(16);
              bottom: 0;
              left: rem(-8);
            }
            &:last-child::after {
              border: none;
            }
            &:hover {
              opacity: 0.7;
              transition: opacity 200ms ease-out;
            }
          }
          a {
            text-decoration: none;
            color: $main-navigation-item-v2-menu-nested-back-link-font-color !important;
            font-family: $font-body;
            font-size: $main-navigation-item-v2-menu-nested-back-link-font-size;
            font-style: $main-navigation-item-v2-menu-nested-back-link-font-style;
            font-weight: $main-navigation-item-v2-menu-nested-back-link-font-weight;
            line-height: $main-navigation-item-v2-menu-nested-back-link-line-height;
          }

          i {
            display: inline-block;
            opacity: 0;
            transform: translateX(20px);
            transition: opacity 0.3s ease, transform 0.5s ease;
          }
          @include breakpoint(large) {
            &:hover {
              i {
                opacity: 0.7;
                animation: bounceIn 0.6s forwards;
              }
            }
          }
        }

        @include breakpoint(large) {
          &.small li {
            &:nth-child(2n)::after {
              border: none;
            }
          }
          &.medium li {
            &:nth-child(3n)::after {
              border: none;
            }
          }
          &.long li {
            &:nth-child(4n)::after {
              border: none;
            }
          }
          &.x-long li {
            &:nth-child(5n)::after {
              border: none;
            }
          }
        }
      }
    }

    .navigation__menu-nested__view-all-link {
      width: 100%;
      text-align: left;
      pointer-events: all;
      display: inline-block;
      text-decoration: none;
      color: $main-navigation-item-v2-menu-nested-back-link-font-color !important; //Styleguide
      font-family: $font-body;
      font-size: $main-navigation-item-v2-menu-nested-back-link-font-size;
      font-style: $main-navigation-item-v2-menu-nested-back-link-font-style;
      font-weight: $main-navigation-item-v2-menu-nested-back-link-font-weight;
      line-height: $main-navigation-item-v2-menu-nested-back-link-line-height;
      padding: rem(34) rem(20);
      @media screen and (min-width: 641px) and (max-width: 1025px) {
        text-align: center;
      }
      @include breakpoint(large) {
        text-align: left;
        padding: rem(34) rem(20) 0 rem(20);
        div {
          width: fit-content;
          display: inline-block;
        }
        &:hover {
          opacity: 0.7;
          transition: opacity 200ms ease-out;
        }
      }
      i {
        display: inline-block;
        opacity: 0;
        transform: translateX(20px);
        transition: opacity 0.3s ease, transform 0.5s ease;
        &::before {
          font-size: rem(10)
        }
      }
      @include breakpoint(large) {
        &:hover {
          i {
            opacity: 0.7;
            animation: bounceIn 0.6s forwards;
          }
        }
      }
    }
  }
}

//Navigation Animations
@keyframes open-modal {
  0% { transform: translateX(100%); display: none; }
  10% { display: block }
  100% { transform: translateX(0); display: block; }
}

@keyframes close-modal {
  0% { transform: translateX(0); display: block; }
  90% { transform: translateX(100%); }
  100% { display: block }
}

@keyframes close-modal-nav {
  0% { height: var(--multiply-height); opacity: 1; display: block; }
  100% { height: 0; opacity: 0; display: none; }
}

@keyframes open-modal-nav {
  0% { height: 0; opacity: 0; display: none; }
  100% { height: var(--multiply-height); opacity: 1; display: block; }
}

@keyframes bounceIn {
  0% { opacity: 0; transform: translateX(20px); }
  60% { opacity: 0.7; transform: translateX(5px); }
  80% { transform: translateX(8px); }
  100% { transform: translateX(5px); }
}
.hero-heading {
  padding: 0; //overwrite general sections padding

  padding-top: 50px;

  .hero-heading__content--text {
    text-align: center;

    * {
      color: $color-on-background;
    }

    .hero-heading__content__title {
      @include font-heading-hero-content-xl();
      font-size: $hero-heading-font-size-small !important;
      padding-left: $hero-heading-pl-mobile;
      padding-right: $hero-heading-pr-mobile;
      padding-bottom: rem(30) !important;
      @include breakpoint(medium) {
        font-size: $hero-heading-font-size-big !important;
        padding-left: $hero-heading-pl;
        padding-right: $hero-heading-pr;
        padding-bottom: calc(#{$hero-heading-title-bottom} / 2) !important;;
      }
    }
  }

  &.hero-heading--media {
    padding: 0;

    @include aspect-ratio(640, 480, ".hero-heading__content");

    @include breakpoint(large) {
      @include aspect-ratio(1920, 800, ".hero-heading__content");
    }

    .hero-heading__content {
      overflow: hidden;
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
    }

    .hero-heading__content--text {
      background: $hero-heading-content-bg;

      & > div {
        position: absolute;
        top: auto;
        bottom: calc(#{$hero-heading-title-bottom} / 2);

        width: 100%;
      }

      * {
        color: $hero-heading-title-color;
      }

      // PIAGGIO-9042: visibility with zoom 400%
      @media screen and (max-width: 320px) {
        .hero-heading__content__title {
          display: -webkit-box;
          overflow: hidden;
          padding-bottom: 0 !important;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }

    .hero-heading__content--no-bg {
      background: transparent;
    }

    @include breakpoint(large) {
      .hero-heading__content__title {
        bottom: $hero-heading-title-bottom;
        top: auto;
      }
    }

    &.hero-heading--mini-form {
      &:before {
        padding-top: rem(746);
      }

      @include breakpoint(medium down) {
        &:before {
          content: none;
        }
      }

      & > .hero-heading__content {
        position: absolute;
        top: 0;
        @include breakpoint(large) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        @include breakpoint(medium down) {
          position: relative;
          margin-bottom: -155px;
        }

        .hero-heading__content--media {
          position: absolute;
          @include breakpoint(medium down) {
            @include aspect-ratio(9, 16, ".slide-img__image");
            &:before {
              padding-top: Min(177.77778%, 745px);
            }
          }
        }
      }

      .toggle-play {
        @include breakpoint(medium down) {
          left: 16px;
          bottom: 170px;
        }
      }
    }
  }
}

.hero-heading--title-below {
  .hero-heading__title {
    text-align: center;
    margin: 3rem 0 0;
  }
}

&.t-model-version,
.t-model-version {
  .hero-heading__content--text {
    display: none;
  }

  .hero-heading {
    display: none;
  }
}

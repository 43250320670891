.dealer-locator-detail {
  .categories-navigation.hooper {
    li a:after {
      border-bottom: unset;
    }
  }
  .hooper-list {
    .hooper-slide {
      margin-bottom: rem(5) !important;
      .button.button--text {
        &:after {
          bottom: rem(-5) !important;
        }
      }
    }
  }
}

.breadcrumb__back {
  min-height: unset !important;
  padding: 0 !important;
  margin: 0 !important;
  left: rem(16) !important;
  &::after {
    display: none;
  }
  @include breakpoint(large) {
    left: rem(40) !important;
  }
  font-size: 1.2rem !important;
  .icon-arrow-sx {
    font-size: 1.2rem !important;
  }
  span {
    margin-left: 0 !important;
  }
}

.card-product {

  .card-product__name {
    font-size: 14px !important;
    font-weight: 700 !important;
  }

  &.card-product-vehicle {
    &:hover,
    &:focus {
      .card-product__name {
        text-decoration: unset;
      }
    }
  }

  &.card-product--accessory,
  &.card-product--service {
    &:hover,
    &:focus {
      @if $card-product-hover-roller {
        .card-product__price,
        .card-product__name {
          color: $text-color-black;
        }
      }
    }
  }

  .custom-swatches-colors-container {
    width: fit-content;
    display: flex;
    margin: rem(15) auto;

    .custom-swatches-color {
      display: flex;
      gap: rem(18);
      margin-left: rem(4);
      margin-right: rem(4);
      .color-icon {
        width: rem(14);
        height: rem(14);
        border-radius: 100%;
        cursor: pointer;
      }
    }
  }

  &.card-product--family {

    .card-product__name {
      text-transform: capitalize;
    }
  }
}

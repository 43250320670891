.hub-links.section {
  .hub-links__title {
    letter-spacing: -0.05em !important;
    color: $color-on-background;
    h4 {
      font-weight: 400;
    }
  }
  .hub-links__link-container {
    margin-top: rem(40);
    .hub-links__icon-container {
      .hub-links__text {
        letter-spacing: -0.05em !important;
        text-transform: unset;
        text-decoration: underline;
        text-decoration-thickness: rem(1);
        text-underline-offset: rem(3);

        @if $button-text-underline {
          position: relative;
        }
      }
    }
  }
}

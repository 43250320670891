.section-hotspot,
.section-vehicle-premium,
.section.call-to-action {
  padding-top: rem(20) !important;
  padding-bottom: rem(20) !important;

  @include breakpoint(large) {
    padding-top: rem(60) !important;
    padding-bottom: rem(60) !important;
  }
}
.section.section-editorial {
  padding-top: rem(60) !important;
  padding-bottom: rem(60) !important;
  @include breakpoint(large) {
    padding-top: rem(60) !important;
    padding-bottom: rem(60) !important;
  }
}

.section-wall-image {
  @include breakpoint(large) {
    padding-top: rem(40) !important;
    padding-bottom: rem(40) !important;
  }
}

.section-hotspot,
.section-vehicle-premium {
  @include breakpoint(large) {
    padding-top: rem(45) !important;
    padding-bottom: rem(45) !important;
  }
}

.section.call-to-action {
  @include breakpoint(large) {
    padding-top: rem(60) !important;
    padding-bottom: rem(44) !important;
  }
}


.listing-header {
  align-items: center;
  .carousel-product__navigation {
    width: rem(141);
    padding-right: 0 !important;
    .button.button--text.button--medium::after {
      display: none;
    }
    span {
      border-bottom: solid 1px;
      padding-bottom: 1px;
    }
  }
  div {
    .listing-header__heading {
      font-size: rem(28);
      @include breakpoint(large) {
        font-size: rem(36);
      }
      @include breakpoint(medium) {
        font-size: rem(36);
      }
    }
  }
}
a.carousel-product__viewall.button.button--text.button--medium {
  display: flex;
}
.section.section--tertiary-1-accessories {
  padding-top: rem(48);
  padding-bottom: rem(48);
  @include breakpoint (large) {
    padding-top: rem(48);
    padding-bottom: rem(64);
  }
  @include breakpoint (medium) {
    padding-top: rem(48);
    padding-bottom: rem(64);
  }
}
.card-product {
  border-radius: rem(8) !important;
}

#uuid-2484b618-7452-4b34-847a-f0a8a908c9f1 {
  .listing-header__heading {
    @include breakpoint(large) {
      text-align: left !important;
    }
    @include breakpoint(medium) {
      text-align: left !important;
    }
  }
}




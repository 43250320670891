.button {
  &.button--text,
  &.button--text-white {

    &.button--small {
      padding: 0px !important;
    }

    &.button--medium {
      padding: 0px !important;
    }

    &.button--large {
      padding: 0px !important;
    }
  }

  &.button--text,
  &.button--text-white {
    &::after {
      transition: none !important;
      left: 0px !important;
      right: 0px !important;
      width: 100% !important;
    }
  }
}

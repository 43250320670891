.gigya-screen.v2.portrait.vespa-gigya-app {
  padding-left: 0 !important;
  padding-right: 0 !important;
  .gigya-label-text.gigya-composite-control.gigya-composite-control-label.main-text,
  .gigya-composite-control.gigya-composite-control-submit
  {
    text-align: center;
  }
  .gigya-composite-control.gigya-composite-control-submit {
    text-transform: uppercase;
    .gigya-input-submit:hover {
      background-color: $white;
      border: 1px solid $text-color-black;
      color: $text-color-black;
    }
  }
  .gigya-password-visible,
  .gigya-password-invisible {
    display: none;
  }
  .gigya-composite-control.gigya-composite-control-header.reset-password-title,
  .icon-text__title {
    color: $text-color-black;
    font-family: $font-heading-xl-font-family;
    line-height: $font-heading-l-line-height-large !important;
    font-size: $font-heading-l-font-size-large !important;
    font-style: $font-heading-xl-font-style !important;
    font-weight: $font-heading-xl-font-weight !important;
  }
  .gigya-label-text.gigya-composite-control.gigya-composite-control-label.main-text,
  .editorial-icon__text.text--body {
    color: $text-color-black;
    font-family: $font-heading-m-font-family;
    line-height: $font-heading-xs-line-height;
    font-size: $font-button-m-font-size;
    font-style: $font-heading-xl-font-style;
  }
  .grid-x.align-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .gigya-label-text,
  .update-psw-label {
    color: $text-color-black;
    font-family: $font-body-font-family;
    font-size: $font-body-font-size !important;
    font-style: $font-body-font-style;
    font-weight: $font-body-font-weight;
    line-height: $font-body-line-height;
  }
  .gigya-input-password,
  .gigya-required-display {
     color: $text-color-black;
     font-family: $font-body-font-family;
  }
}

//MOBILE
@media screen and (max-width: 640px) {
  .gigya-screen.v2.portrait.vespa-gigya-app {
    width: auto;
  }
}
.card-promotion{
  margin-top: rem(40);
  margin-bottom: rem(40);
  @include breakpoint(large){
    margin-top: rem(60);
    margin-bottom: rem(60);
  }
  .card-promotion__content {
    padding: 0;
    & > div {
      padding: rem(20) rem(54) rem(20) rem(15);
      @include breakpoint(large) {
        padding: rem(20) !important;
      }
    }
  }
  .icon-plus {
    margin-bottom: rem(5);
    @include breakpoint(large) {
      position: absolute;
      margin-bottom: 0;
      bottom: rem(10) !important;
    }
  }
  .card-promotion__duration {
    font-size: rem(16);
    line-height: normal;
    margin-bottom: 8px;
  }
  .card-promotion__title {
    font-size: rem(28);
    line-height: normal !important;
    @include breakpoint(large){
      font-size: rem(36);
    }
  }

  .card-promotion__text {
    font-size: 16px;
    @include breakpoint(large){
      span {
        p {
          padding-bottom: rem(43);
          margin-bottom: rem(15);
        }
      }

    }
    line-height: normal;
  }

  .card-promotion__cta {
    display: none;
    @include breakpoint(large) {
      font-size: 1.8rem;
      line-height: 1;
      font-style: normal;
      font-weight: 700;
      font-family: Inter, sans-serif, Arial, Helvetica;
      letter-spacing: -.05em;
      display: inline-block;
      bottom: rem(20);
      position: absolute;
      border-bottom: solid 1px;
    }

  }
}
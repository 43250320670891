////////////////////////////////////////
// Footer logo
.footer__logo {
  margin: 0 auto;
  display: block;
  max-width: 100px;
}

////////////////////////////////////////
// Footer Main
.footer__main {
  .accordion {
    padding-bottom: 0px !important;
    @include breakpoint(large) {
      padding-bottom: 20px !important;
    }
    button {
      font-size: rem(14);
      font-weight: 700;
      @include breakpoint(large) {
        font-size: inherit;
        font-weight: inherit;
      }
    }
    .font-size-ms {
      border-bottom: unset;
      .accordion__content {
        ul > li {
          padding: rem(12) 0;
          @include breakpoint(large) {
            padding: 0;
          }
        }
      }
    }
  }
}

.footer__legal {
    @include font-body-ms;
    @include application-font($font-body-ms-font-family, 400);
  }
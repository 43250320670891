.card-small {
  background-color: transparent;
  border-radius: rem(8);
  &__picture {
    .image {
      border-radius: rem(8);
    }
    .card-small {
      &__gradient {
        border-radius: rem(8);
      }
    }
  }
  &.image-half {
    background-color: $color-tertiary-1;
    .card-small__picture {
      .image {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
}
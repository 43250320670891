.card-promotion-small {
  &__img {
    picture {
      img {
        border-top-left-radius: rem(8);
        border-top-right-radius: rem(8);
      }
    }
  }
  &__content {
    border-bottom-left-radius: rem(8);
    border-bottom-right-radius: rem(8);
    > i {
      margin: auto rem(15) rem(20);
    }
  }
}
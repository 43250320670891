/**
* Design System for Piaggio Group
*/

.text--black {
  color: $text-color-black;
}

.text--white {
  color: $text-color-white;
}

.text--primary {
  color: $text-color-primary;
}

.text--secondary {
  color: $text-color-secondary;
}

.text--heading-xl {
  @include font-heading-xl;
}

.text--heading-grid {
  padding: 0 calc((100vw - 100rem) / 2)
}

.text--heading-l {
  @include font-heading-l;

  &.text--heading-l--section {
    @include breakpoint(medium) {
      @if $general-heading-l-decoration {
        position: relative;
        &::before,
        &::after {
          font-family: moto-guzzi-icons !important;
          position: absolute;
          top: 0;
          right: -90px;
          content: "\f10c";
          font-size: 1.8rem;
        }

        &::before {
          transform: scaleX(-1);
          left: -90px;
          right: auto;
        }
      }
    }
  }
}

.text--heading-m {
  @include font-heading-m;
}

.text--heading-s {
  @include font-heading-s;
}

.text--heading-ms {
  @include font-heading-ms;
}

.text--heading-xs {
  @include font-heading-xs;
}

.text--heading-xxs {
  @include font-heading-xxs;
}

.text--body {
  @include font-body;
}

.text--body-s {
  @include font-body-s;
}

.text--body-xs {
  @include font-body-xs;
}

.text--quote {
  @include font-quote;
}

.text--button-l {
  @include font-button-l;
}

.text--button-m {
  @include font-button-m;
}

.text--button-text-l {
  @include font-button-text-l;
}

.text--button-text-m {
  @include font-button-text-m;
}

.text--center {
  text-align: center;
}
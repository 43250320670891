@media screen and (max-width: 640px) {
  .hero-heading {
    .editorial__text {
      line-height: 31px !important;
      font-size: 24px !important;
    }
  }
  .hero-heading.hero-heading--media  .hero-heading__content__title {
    padding-bottom: rem(20) !important;
    text-align:center !important;
  }
}

.hero-heading__content {
  .toggle-play {
    background-color: $color-tertiary-1;
    transition: 500ms;
    width: rem(30);
    height: rem(30);
    border-radius: rem(30);

    button {
      font-size: unset;
      display: flex;
      width: rem(30);
      height: rem(30);

      &:hover {
        background: unset;
      }
    }

    i {
      margin: auto;
      font-size: rem(13);
      font-weight: 700 !important;
      color: $color-primary;
    }

    &:hover {
      background-color: $color-primary;

      i {
        color: $color-tertiary-1;
      }
    }
  }
}

.card-event__item {
  width: 254px;
  background-color: white;
}

.card-event__img {
  img {
    height: rem(190);
  }
}
.card-event__list {
  flex-wrap: wrap;
  //margin-left: 75px;
  display: flex;
  justify-content: flex-start;
  gap: rem(30);
  margin-top: rem(30);
  padding-bottom: rem(20);
}
.listing-events {
  background-color: black;
  margin: 0 auto;
  a {
    text-decoration: none;
  }
}
.card-event__content {
  display: flex;
  flex-direction: column;
}
.listing-events-header-btn {
  display: flex;
  justify-content: center;
}

.card-event__category {
  font-family: $font-heading-xxs-font-family;
  font-weight:$font-heading-xxs-font-weight;
  font-size: $font-heading-xxs-font-size;
  color: black;
  text-transform: uppercase;
  line-height: 16px;
  padding-bottom: 2px;
  border-bottom: solid 2px;
  border-color: #EC3935;
  margin-bottom: 4px;
  width: fit-content;
  margin-left: rem(8);
  margin-top: rem(12);
}
.card-event__title {
  font-family: $font-heading-ml-font-family;
  font-weight:$font-heading-ml-font-weight;
  font-size: $font-heading-s-font-size;
  color: black;
  line-height: 24px;
  margin-left: rem(8);
  margin-right: rem(8);
  margin-bottom: rem(8);
}
.card-event__title::first-letter{
  text-transform: capitalize;
}
.event-hidden {
  display: none;
}
.listing-events-header-btn {
    overflow-x: hidden;
  .show-for-sr {
    color: white !important;
    width: auto !important;
    height: auto !important;
    position: unset !important;
    font-family: $font-heading-ml-font-family;
    font-weight:$font-heading-ml-font-weight;
    font-size: $font-heading-s-font-size;
    line-height: 24px;
    display: block;
    margin-top: 4px;
    font-style: normal;
  }
  .show-for-sr::first-letter {
    text-transform: capitalize;
  }
  i {
    width: 32px;
    height: 32px;
    color: white;
    font-size: 24px;
  }
  button {
    display: inline-block;
  }
  button:focus {
    i {
      color: #ec3935;
    }
  }
  .button.button--icon {
    padding: 0;
  }

  li {
    margin-right: 40px;
    @include breakpoint(large) {
      margin-right: 80px;
    }
  }
  li:last-child {
    margin-right: 0;
  }
}
.classFocusOn {
  color: #ec3935 !important;
}
//MOBILE
@media screen and (max-width: 678px) {
  .listing-events-header-btn {
    display: flex;
    //margin-left: 20px;
    justify-content: flex-start;
    overflow-x: scroll;
    scrollbar-color: black black;
    li:first-child {
      margin-left: 20px;
    }
    li:last-child {
      margin-right: 20px;
    }
    li:last-child {
      button:focus {
        i {
          color: #ec3935;
        }
      }
    }
  }
  .card-event__item {
    width: 177px;
    height: 225px;
  }
  .card-event__list {
    gap: rem(5);
    margin-left: 0;
  }
  .card-event__img {
    img {
      height: rem(140);
    }
  }
  .card-event__title {
    font-size: rem(13);
    line-height: rem(17.5);
  }
  .card-event__category {
    font-size: rem(9);
    line-height: rem(11.5);
  }
}

@media screen and (min-width: 1106px) {
  .card-event__list {
    width: 1106px;
  }
}
@media screen and (min-width:1166px) {
  .card-event__list {
    margin-right: 30px;
    margin-left: 30px;
  }
}
@media screen and (max-width: 1105px) and (min-width: 822px) {
  .card-event__list {
    width: 822px;
  }
}
@media screen and (max-width: 821px) and (min-width: 679px) {
  .card-event__list {
    width: 538px;
  }
}
@media screen and (min-width: 678px) {
  .listing-events {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
  }
}


/* Navigation V2 */
.navigation-v2 {
  display: none;
  width: 100%;
  height: 100%;
  background-color: $navigation-v2-background-color;
  overflow-y: hidden;
  color: $navigation-v2-item-color;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(233, 233, 233, 1);

  @include breakpoint(large) {
    display: block;
    min-height: rem(48);
    height: fit-content;
    border-bottom: none;
    overflow-y: hidden;

    &__content {
      border-bottom: 1px solid rgba(233, 233, 233, 1);
    }
  }

  &__content-wrapper {
    @include breakpoint(large) {
      overflow: hidden;

      &.overlay-active {
        &::before {
          content: "";
          position: fixed;
          top: rem(120);
          left: var(--float-overlay);
          width: 100%;
          max-width: rem(1920);
          height: 100vh;
          height: 100vhs;
          z-index: 0;
          animation: overlay-active 500ms forwards;
          pointer-events: none;
        }
      }
    }
  }

  &__content-wrapper, &__content-v2, &__menu {
    height: fit-content;
    @include breakpoint(large) {
      height: 100%;
    }
  }

  &__menu {
    font-size: $navigation-v2-menu-font-size;
    color: $navigation-v2-menu-text-color !important;
    font-weight: $navigation-v2-menu-font-weight !important;
    @include breakpoint(large) {
      display: flex;
      width: fit-content;
      margin: 0 auto;
    }

    &__list {
      display: flex;
      height: rem(48);
      position: relative;
      @include breakpoint(large) {
        margin: 0 rem(20);
      }

      &__item, a, &__item__voice {
        font-size: $navigation-v2-menu-font-size;
        color: $navigation-v2-menu-text-color;
        font-weight: $navigation-v2-menu-font-weight;
        text-transform: none;
        margin: auto rem(22);
        @include breakpoint(large) {
          font-weight: $navigation-v2-menu-font-weight-l;
          margin: auto 0;
        }
        i::before {
          color: $navigation-v2-menu-text-color;
        }

        .icon-arrow-dx {
          &::before {
            font-size: rem(13);
          }
        }
      }

      button span {
        @include breakpoint(large) {
          margin: rem(8);
        }
      }

      @include breakpoint(large) {
        &__item {
          &::after {
            content: "";
            width: 0;
            height: rem(2);
            position: absolute;
            bottom: rem(-1);
            background-color: $color-primary;
          }
          .icon-arrow-down {
            &::before {
              font-size: rem(13);
            }
          }
          &.active {
            .navigation-v2__menu__list__item__voice {
              font-weight: 500 !important;
              color: $navigation-v2-menu-text-color-active !important;
            }
            i {
              &::before {
                color: $navigation-v2-menu-text-color-active !important;
              }
            }
            &::after {
              animation: menu-item-active 300ms forwards;
            }

            .icon-arrow-down {
              margin: 0 0 rem(5) 0;
              animation: rotate-arrow 300ms forwards;

              &::before {
                font-size: rem(13);
              }
            }
          }
          &:not(.active) {
            &::after {
              animation: menu-item-not-active 300ms forwards;
            }
            .icon-arrow-down {
              margin: 0;
              animation: rotate-back-arrow 300ms forwards;
            }
          }
        }
      }
      @media screen and (min-width: 641px) and (max-width: 1025px) {
        &__item {
          &::after {
            content: "";
            width: 0;
            height: rem(1);
            position: absolute;
            bottom: rem(-1);
            background-color: $color-primary;
          }
          &.active {
            &::after {
              animation: menu-item-active 300ms forwards;
            }

            .navigation-v2__menu__list__item__voice {
              animation: voice-item-active 400ms forwards;
            }

            .icon-arrow-dx {
              animation: move-arrow 200ms forwards;
            }
          }
          &:not(.active) {
            &::after {
              width: 0;
              animation: menu-item-not-active 300ms forwards;
            }

            .navigation-v2__menu__list__item__voice  {
              animation: voice-item-not-active 400ms forwards;
            }

            .icon-arrow-dx {
              margin: 0;
              animation: move-back-arrow 200ms forwards;
            }
          }
        }
      }
    }
  }
}

//Mobile View - NAVIGATION
.header__actions.v2 {
  height: 0;
  display: flex;
  align-items: center;
  background-color: $navigation-v2-header-actions-mb-background-color;
  &.mobile {
    animation: close-mobile-menu-nav 500ms forwards;
    display: none;
    opacity: 0;
    height: rem(2);
    &.visible {
      overflow-y: scroll;
      overflow-x: hidden;
      width: 100%;
      height: calc(100vh - 70px);
      height: calc(100vhs - 70px);
      display: flex;
      flex-direction: column;
      position: relative;
      opacity: 1;
      animation: open-mobile-menu-nav 400ms forwards;
      //TABLET ADAPTATION
      @media screen and (min-width: 641px) and (max-width: 1025px) {
        overflow: unset;
        height: 100vh;
        height: 100vs;
        background-color: $navigation-v2-header-actions-mb-background-color;
        &::before {
          content: "";
          position: absolute;
          z-index: 100;
          height: 100vh;
          width: 60%;
          top: -5%;
          left: 50%;
          box-shadow: inset 0px 4px 80px 0px rgba(0, 0, 0, 0.4);
          pointer-events: none;
        }
      }

      .navigation-v2 {
        padding: rem(20);
        height: fit-content;
        overflow-y: visible;
        display: block;
        background-color: $navigation-v2-background-color;
        //TABLET ADAPTATION
        @media screen and (min-width: 641px) and (max-width: 1025px) {
          width: 50%;
          margin-right: auto;
        }
        &__menu {
          width: 100%;
          &__list {
            width: 100%;
            padding: rem(12) 0;
            &__item, a, button {
              width: 100%;
              margin: auto 0;
              &__voice {
                width: 100%;
                text-align: left;
                margin-left: 0;
              }
              i::before {
                color: $navigation-v2-menu-voice-icon-color;
              }
            }
          }
        }
      }

      .header__menu-services {
        height: fit-content;
        width: 100%;
        padding: rem(20);
        background-color: $navigation-v2-header-actions-mb-background-color;
        &.is-ios {
          padding: rem(20) rem(20) rem(60) rem(20);
        }
        //TABLET ADAPTATION
        @media screen and (min-width: 641px) and (max-width: 1025px) {
          width: 50%;
          margin-right: auto;
        }
        .header__menu-items {
          width: 100%;
          display: flex;
          flex-direction: column;
          text-align: left;
          li {
            padding: rem(12) 0;
            i {
              color: #000;
              font-size: $navigation-v2-header-menu-services-icon-font-size;
              margin-right: rem(8);
              line-height: 0;
              height: rem(24);
              width: rem(24);
              display: inline-block;
            }
            .navigation-v2__menu-nested__back {
              &.contact-us {
                i {
                  color: $main-navigation-item-v2-menu-nested-back-button-text-color !important;
                  width: rem(16) !important;
                  height: rem(16) !important;
                  margin-right: rem(12);
                  &::before {
                    font-size: $main-navigation-item-v2-menu-nested-back-button-font-size !important;
                    font-weight: 700;
                  }
                }
              }
            }
          }
          & > li, li a, li a span, li ul {
            font-size: $navigation-v2-header-menu-services-text-font-size;
            font-style: $navigation-v2-header-menu-services-text-font-style;
            font-weight: $navigation-v2-header-menu-services-text-font-weight;
            line-height: $navigation-v2-header-menu-services-text-line-height;
            color: $navigation-v2-menu-text-color;
          }
          li ul {
            //fix icon align
            transform: translateY(-40%);
          }
          .header__menu-services__gigya-login {
            order: 4;
            .header-v2__login-gigya {
              i:before {
                font-size: rem(16);
                //fix icon align
                display: inline-block;
                transform: translateY(1px);
              }
              a {
                transform: unset;
              }
            }
          }
          .header__menu-services__dealer-locator {
            order: 3;
          }
          .header__menu-services__store {
            order: 1;
          }
          .header__menu-services__contact-us {
            .contact-us-back-button {
              @media screen and (min-width: 641px) and (max-width: 1025px) {
                display: none;
              }
            }
            order: 2;
            @media screen and (min-width: 641px) and (max-width: 1025px) {
              background: linear-gradient(to right, transparent -200%,$color-primary 50%, $color-primary 50%, transparent 200%);
              background-size: 0 1px;
              background-position: 50% 100%;
              background-repeat: no-repeat;
              &.active {
                .span {
                  font-weight: 500 !important;
                  color: $navigation-v2-menu-text-color-active !important;
                }
                .icon-arrow-down {
                  animation: move-arrow-contacts 200ms forwards;
                }
                .icon-contact-us {
                  width: 0;
                  margin: 0;
                  &::before {
                    display: none;
                  }
                }
                  animation: menu-item-active-contacts 300ms forwards;
              }
              &:not(.active) {
                  animation: menu-item-not-active-contacts 300ms forwards;
                .icon-arrow-down {
                  margin: 0;
                  animation: move-back-arrow-contacts 200ms forwards;
                }
              }
            }
            div {
              display: inline-block;
            }
            span {
              display: inline-block;
              width: calc(100% - 88px);
              color: $navigation-v2-menu-text-color;
              //fix icon align
              transform: translateY(-40%);
            }
            .icon-arrow-down {
              height: rem(16);
              width: rem(16);
              font-size: rem(13);
              float: right;
              transform: translateY(30%) rotate(-90deg);
              margin: 0;
            }
            ul {
              &:not(.show) {
                flex-direction: column;
                padding: rem(20);
                position: absolute;
                height: 100%;
                width: 100%;
                background-color:$navigation-v2-background-color;
                left: 0;
                bottom: 0;
                z-index: 100;
                transform: translateX(100%);
                animation: close-contacts 600ms forwards;
                opacity: 0;
                @media screen and (min-width: 641px) and (max-width: 1025px) {
                  width: 50%;
                  animation: close-contacts-tb 600ms forwards;
                }
              }
              span, a {
                width: 100%;
                transform: unset;
              }
              &.show {
                display: flex;
                flex-direction: column;
                padding: rem(20);
                position: absolute;
                height: 100%;
                width: 100%;
                background-color:$navigation-v2-background-color;
                left: 0;
                bottom: 0;
                z-index: 100;
                transform: translateX(0);
                animation: open-contacts 600ms forwards;
                @media screen and (min-width: 641px) and (max-width: 1025px) {
                  text-align: center;
                  width: 50%;
                  transform: translateX(200%);
                  animation: open-contacts-tb 600ms forwards;
                }
              }
            }
          }
        }
      }
    }
  }
}
//End Mobile View - NAVIGATION

//Mobile Navigation Animations
@keyframes close-mobile-menu-nav {
  0% { opacity: 0; display: flex; }
  100% { opacity: 0; display: none; }
}
@keyframes open-mobile-menu-nav {
  0% { opacity: 0; display: none; }
  100% { opacity: 1; display: flex; }
}

@keyframes open-contacts {
  0% { transform: translateX(100%); display: none; }
  10% { display: flex }
  100% { transform: translateX(0); display: flex; }
}

@keyframes close-contacts {
  0% { transform: translateX(0); display: flex; }
  90% { transform: translateX(100%); }
  100% { display: none }
}

@keyframes open-contacts-tb {
  0% { transform: translateX(200%); display: none; }
  10% { display: flex }
  100% { transform: translateX(100%); display: flex; }
}

@keyframes close-contacts-tb {
  0% { transform: translateX(100%); display: flex; }
  90% { transform: translateX(200%); }
  100% { display: none }
}

@keyframes rotate-arrow {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(-180deg); }
}

@keyframes rotate-back-arrow {
  0% { transform: rotate(-180deg); }
  100% { transform: rotate(0deg); }
}

@keyframes menu-item-active {
  0% { width: 0; }
  100% { width: 100%; }
}

@keyframes menu-item-not-active {
  0% { width: 100%; }
  100% { width: 0; }
}

@keyframes menu-item-active-contacts {
  0% { background-size: 0 1px; }
  100% { background-size: 100% 1px; }
}

@keyframes menu-item-not-active-contacts {
  0% { background-size: 100% 1px; }
  100% { background-size: 0 1px; }
}

@keyframes overlay-active {
  0% { background-color: transparent; backdrop-filter: unset; }
  100% { background-color: rgba(0, 0, 0, 0.6); backdrop-filter: blur(12px); }
}

@keyframes menu-item-voice-active {
  0% { width: 0; opacity: 0; }
  100% { width: 100%; opacity: 1; }
}

@keyframes menu-item-voice-not-active {
  0% { width: 100%; opacity: 1; }
  100% { width: 0; opacity: 0; }
}

@keyframes voice-item-active {
  0% { color: #000; padding-left: 0; order: 1; }
  100% { color: #575A5A!important; padding-left: rem(8); order: 2; }
}

@keyframes voice-item-not-active {
  0% { color: #575A5A!important; padding-left: rem(8); order: 2; }
  100% { color: #000; padding-left: 0; order: 1; }
}

@keyframes move-arrow {
  0% { order: 2; opacity: 1; padding-left: rem(0); }
  50% { order: 1; opacity: 0; }
  100% { order: 1; opacity: 1; padding-left: rem(12); }
}

@keyframes move-back-arrow {
  0% { order: 1; opacity: 1; padding-left: rem(12); }
  50% { order: 1; opacity: 0; }
  100% { order: 2; opacity: 1; padding-left: rem(0); }
}

@keyframes move-arrow-contacts {
  0% { float: right; opacity: 1; margin-left: rem(0); margin-right: rem(0); }
  50% { float: left; opacity: 0; }
  100% { float: left; opacity: 1; margin-left: rem(12); margin-right: rem(10); }
}

@keyframes move-back-arrow-contacts {
  0% { float: left; opacity: 1; margin-left: rem(12); margin-right: rem(10); }
  50% { float: left; opacity: 0; }
  100% {  float: right; opacity: 1; margin-left: rem(0); margin-right: rem(0); }
}
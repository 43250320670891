/**
 * Design System for Piaggio Group
*/

@font-face {
  font-family: "vespa-icons";
  src: url("../fonts/vespa/vespa-icons.eot?4793564da31be0cef218d94435c12e51?#iefix")
      format("embedded-opentype"),
    url("../fonts/vespa/vespa-icons.woff2?4793564da31be0cef218d94435c12e51")
      format("woff2"),
    url("../fonts/vespa/vespa-icons.woff?4793564da31be0cef218d94435c12e51")
      format("woff"),
    url("../fonts/vespa/vespa-icons.ttf?4793564da31be0cef218d94435c12e51")
      format("truetype"),
    url("../fonts/vespa/vespa-icons.svg?4793564da31be0cef218d94435c12e51#vespa-icons")
      format("svg");
  font-display: swap;
}

@import "tokens-styleguide-vespa";
@import "tokens-components-vespa";
